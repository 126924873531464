import { map, values } from 'lodash';
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null,
  raceList: [],
  RaceType: [],
  raceListById: [],
  updateRace: [],
  deleteRace: [],
  registerList: [],
  addRegister: [],
  registerListById: [],
  updateRegister: [],
  deleteRegister: [],
  subTypeList: [],
  reservationList: [],
  addReservation: [],
  reservationListById: [],
  updateReservation: [],
  deleteReservation: [],
  shopList: [],
  shoppingList: [],
  addShopping: [],
  shoppingListById: [],
  updateShopping: [],
  deleteShopping: [],
  spotList: [],
  addSpot: [],
  spotListById: [],
  updateSpot: [],
  deleteSpot: [],
  raceSubListById: [],
  raceTypeBy: [],
  companyList: [],
  addCompany: [],
  companyListById: [],
  updateCompany: [],
  deleteCompany: [],
  outletList: [],
  addOutlet: [],
  outletListById: [],
  updateOutlet: [],
  deleteOutlet: [],
  newsList: [],
  newsListById: [],
  addNews: [],
  updateNews: [],
  deleteNews: [],
  serviceList: [],
  serviceListById: [],
  addService: [],
  updateService: [],
  deleteService: [],
  userDataList: [],
  userDataListById: [],
  addUserData: [],
  updateUserData: [],
  deleteUserData: [],
  createServiceRequest: [],
  serviceRequestList: [],
  serviceRequestDetails: [],
  updateServiceRequest: [],
  deleteServiceRequest: [],
  bookingList: [],
  bookingDetailsById: [],
  createRaceRound: [],
  raceRoundList: [],
  raceRoundDetails: [],
  updateRaceRound: [],
  deleteRaceRound: [],
  sizeList: [],
  addSize: [],
  sizeListById: [],
  updateSize: [],
  deleteSize: [],
  spotSizeListValue: [],
};

// const eventApiUrl =
//   'https://us-central1-event-management-7d935.cloudfunctions.net/';

// const eventApiUrl = 'https://us-central1-almanya-4e69f.cloudfunctions.net/';

// const eventApiUrl =
//   'https://asia-south1-function-mumbai.cloudfunctions.net/api/';

const eventApiUrl = 'https://asia-south1-al-maniya.cloudfunctions.net/api/';

const authToken =
  'eyJhbGciOiJSUzI1NiIsImtpZCI6IjUyZmEwZjE2NmJmZjZiODU5N2FjMGFlMDRlNTllZmYxOTk1N2MyYmIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZXZlbnQtbWFuYWdlbWVudC03ZDkzNSIsImF1ZCI6ImV2ZW50LW1hbmFnZW1lbnQtN2Q5MzUiLCJhdXRoX3RpbWUiOjE2NjIxMDc0NjAsInVzZXJfaWQiOiJDU29ybGg0emVUWG1wamZYbVpuNjRjdWlidFEyIiwic3ViIjoiQ1NvcmxoNHplVFhtcGpmWG1abjY0Y3VpYnRRMiIsImlhdCI6MTY2MjEwNzQ2MCwiZXhwIjoxNjYyMTExMDYwLCJlbWFpbCI6ImthbGFpc2VsdmFuMzk5NEBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsia2FsYWlzZWx2YW4zOTk0QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.cwqQhfS7mXO1aEsaft5pqhGYUMYTmifTWN76DUgDAS3oPL84KEKKrM49PBEJlLp-JTVB9KIpxEG9A16B3E8VjtiRStTJ896I_-UhS-wnYAwJkkLCEcq1UgYtTcKy30JTT1V2QbieJN9SYMwTFsvaeGdnqpZxl3htjtJcRQ94W-01_oPlwHkMa9HEo0qMgQz8rtqxBY9CC5pBJBTlSSCrw22_nZfsqKbZQHgBSb9tjPln-lqa1h3f1M7EmaHZegtDwm50KYxYRLme_RL3zIMOqQ3_AxrfEiokdgLc2WPKC6eO9Vg87UpOZieL7KE-59qjx5TiFcxztKbrjkrXnN0x3w';
const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Event Management Start --------

    // Bookings start ----

    // get booking list
    bookingListSuccess(state, action) {
      state.isLoading = false;
      state.bookingList = action.payload;
    },
    // get booking details by id
    bookingDetailsByIdSuccess(state, action) {
      state.isLoading = false;
      state.bookingDetailsById = action.payload;
    },

    updateBookingDetailsSuccess(state, action) {
      state.isLoading = false;
      state.updateBookingData = action.payload;
    },

    // Bookings end ----

    // Transaction start ----

    // get booking list
    transactionListSuccess(state, action) {
      state.isLoading = false;
      state.transactionList = action.payload;
    },
    // get booking details by id
    transactionDetailsByIdSuccess(state, action) {
      state.isLoading = false;
      state.transactionDetailsById = action.payload;
    },

    // Transaction end ----

    // Race Start -----

    // get race list
    raceListSuccess(state, action) {
      state.isLoading = false;
      state.raceList = action.payload;
    },

    raceListForRaceSuccess(state, action) {
      state.isLoading = false;
      state.raceListForRace = action.payload;
    },

    // get race list by id
    raceListByIdSuccess(state, action) {
      state.isLoading = false;
      state.raceListById = action.payload;
    },

    // get race sub type list by id
    raceSubListByIdSuccess(state, action) {
      state.isLoading = false;
      state.raceSubListById = action.payload;
    },

    // Create race type
    createRaceTypeSuccess(state, action) {
      state.isLoading = false;
      state.RaceType = action.payload;
    },
    // Update race type
    updateRaceTypeSuccess(state, action) {
      state.isLoading = false;
      state.updateRace = action.payload;
    },
    // Delete race type
    deleteRaceTypeSuccess(state, action) {
      state.isLoading = false;
      state.deleteRace = action.payload;
    },
    //Race Registration List
    raceTypeByRegistrationSuccess(state, action) {
      state.isLoading = false;
      state.raceTypeBy = action.payload;
    },
    // Race End -----

    // Registration Start -----

    // get Registration list
    registerListSuccess(state, action) {
      state.isLoading = false;
      state.registerList = action.payload;
    },
    registerListAllSuccess(state, action) {
      state.isLoading = false;
      state.registerListAll = action.payload;
    },
    // get register list by id
    registerListByIdSuccess(state, action) {
      state.isLoading = false;
      state.registerListById = action.payload;
    },
    // Create registration
    createRegisterSuccess(state, action) {
      state.isLoading = false;
      state.addRegister = action.payload;
    },
    // Update registration
    updateRegisterSuccess(state, action) {
      state.isLoading = false;
      state.updateRegister = action.payload;
    },
    // Delete registration
    deleteRegisterSuccess(state, action) {
      state.isLoading = false;
      state.deleteRegister = action.payload;
    },
    //subTypeList
    subTypeListSuccess(state, action) {
      state.isLoading = false;
      state.subTypeList = action.payload;
    },

    // Registration End -----

    //subType
    createRaceSubtypeSuccess(state, action) {
      state.isLoading = false;
      state.RaceSubtype = action.payload;
    },
    subtypeListSuccess(state, action) {
      state.isLoading = false;
      state.subtypeList = action.payload;
    },

    subtypeByIdSuccess(state, action) {
      state.isLoading = false;
      state.subtypeById = action.payload;
    },

    updateSubtypeSuccess(state, action) {
      state.isLoading = false;
      state.updateSubtypeData = action.payload;
    },

    raceSubtypeDeleteSuccess(state, action) {
      state.isLoading = false;
      state.deleteRaceSubtype = action.payload;
    },
    //sub type End ----

    // Reservation Start -----

    // get Reservation list
    reservationListSuccess(state, action) {
      state.isLoading = false;
      state.reservationList = action.payload;
    },
    // get reservation list by id
    reservationListByIdSuccess(state, action) {
      state.isLoading = false;
      state.reservationListById = action.payload;
    },
    // Create reservation
    createReservationSuccess(state, action) {
      state.isLoading = false;
      state.addReservation = action.payload;
    },
    // Update reservation
    updateReservationSuccess(state, action) {
      state.isLoading = false;
      state.updateReservation = action.payload;
    },
    // Delete reservation
    deleteReservationSuccess(state, action) {
      state.isLoading = false;
      state.deleteReservation = action.payload;
    },

    // Reservation End -----

    //Spot Start ----

    // Spot list
    spotListSuccess(state, action) {
      state.isLoading = false;
      state.spotList = action.payload;
    },

    // get spot list by id
    spotListByIdSuccess(state, action) {
      state.isLoading = false;
      state.spotListById = action.payload;
    },
    // Create spot
    createSpotSuccess(state, action) {
      state.isLoading = false;
      state.addSpot = action.payload;
    },
    // Update spot
    updateSpotSuccess(state, action) {
      state.isLoading = false;
      state.updateSpot = action.payload;
    },
    // Delete spot
    deleteSpotSuccess(state, action) {
      state.isLoading = false;
      state.deleteSpotData = action.payload;
    },

    //Spot End ----

    // Size list
    sizeListSuccess(state, action) {
      state.isLoading = false;
      state.sizeList = action.payload;
    },

    spotSizeListBySpotIdSuccess(state, action) {
      state.isLoading = false;
      state.spotSizeListValue = action.payload;
    },

    // get size list by id
    sizeListByIdSuccess(state, action) {
      state.isLoading = false;
      state.sizeListById = action.payload;
    },
    // Create size
    createSizeSuccess(state, action) {
      state.isLoading = false;
      state.addSize = action.payload;
    },
    // Update size
    updateSizeSuccess(state, action) {
      state.isLoading = false;
      state.updateSize = action.payload;
    },
    // Delete size
    deleteSizeSuccess(state, action) {
      state.isLoading = false;
      state.deleteSizeData = action.payload;
    },

    //Size End ----

    // Reservation Start -----

    // get Shopping list
    shoppingListSuccess(state, action) {
      state.isLoading = false;
      state.shoppingList = action.payload;
    },
    // get Shopping list by id
    shoppingListByIdSuccess(state, action) {
      state.isLoading = false;
      state.shoppingListById = action.payload;
    },
    // Create Shopping
    createShoppingSuccess(state, action) {
      state.isLoading = false;
      state.addShopping = action.payload;
    },
    // Update Shopping
    updateShoppingSuccess(state, action) {
      state.isLoading = false;
      state.updateShopping = action.payload;
    },
    // Delete Shopping
    deleteShoppingSuccess(state, action) {
      state.isLoading = false;
      state.deleteShopping = action.payload;
    },

    // Reservation End -----

    // Shop list
    shopListSuccess(state, action) {
      state.isLoading = false;
      state.shopList = action.payload;
    },
    createShopSuccess(state, action) {
      state.isLoading = false;
      state.shop = action.payload;
    },

    shopListSuccess1(state, action) {
      state.isLoading = false;
      state.shopList1 = action.payload;
    },

    shopByRegistrationSuccess(state, action) {
      state.isLoading = false;
      state.shopBy = action.payload;
    },

    updateShopSuccess(state, action) {
      state.isLoading = false;
      state.shopUpdate = action.payload;
    },

    deleteShopSuccess(state, action) {
      state.isLoading = false;
      state.deleteShop1 = action.payload;
    },

    //Vendor Start ----

    // Company list
    companyListSuccess(state, action) {
      state.isLoading = false;
      state.companyList = action.payload;
    },

    // get Company list by id
    companyListByIdSuccess(state, action) {
      state.isLoading = false;
      state.companyListById = action.payload;
    },
    // Create Company
    createCompanySuccess(state, action) {
      state.isLoading = false;
      state.addCompany = action.payload;
    },
    // Update Company
    updateCompanySuccess(state, action) {
      state.isLoading = false;
      state.updateCompany = action.payload;
    },
    // Delete Company
    deleteCompanySuccess(state, action) {
      state.isLoading = false;
      state.deleteCompany = action.payload;
    },

    //Vendor End ----

    //Outlet Start ----

    // Outlet list
    outletListSuccess(state, action) {
      state.isLoading = false;
      state.outletList = action.payload;
    },

    // get Outlet list by id
    outletListByIdSuccess(state, action) {
      state.isLoading = false;
      state.outletListById = action.payload;
    },
    // Create Outlet
    createOutletSuccess(state, action) {
      state.isLoading = false;
      state.addOutlet = action.payload;
    },
    // Update Outlet
    updateOutletSuccess(state, action) {
      state.isLoading = false;
      state.updateOutlet = action.payload;
    },
    // Delete Outlet
    deleteOutletSuccess(state, action) {
      state.isLoading = false;
      state.deleteOutlet = action.payload;
    },

    //Outlet End ----

    // Event Management End --------

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed,
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
    //User List
    userListSuccess(state, action) {
      state.isLoading = false;
      state.userDataList = action.payload;
    },
    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    //Festival Guide
    createFestivalGuideSuccess(state, action) {
      state.isLoading = false;
      state.FestivalGuide = action.payload;
    },

    GuidListSuccess(state, action) {
      state.isLoading = false;
      state.guidList = action.payload;
    },

    guideByIdSuccess(state, action) {
      state.isLoading = false;
      state.guidById = action.payload;
    },

    updateGuideSuccess(state, action) {
      state.isLoading = false;
      state.updateGuide = action.payload;
    },

    deleteGuideSuccess(state, action) {
      state.isLoading = false;
      state.deleteGuide = action.payload;
    },

    //Place Evaluation

    createPlaceEvaluationSuccess(state, action) {
      state.isLoading = false;
      state.placeEvaluationDataAdd = action.payload;
    },

    placeEvaluationListSuccess(state, action) {
      state.isLoading = false;
      state.placeEvaluationDataList = action.payload;
    },

    PlaceEvaluationByIdSuccess(state, action) {
      state.isLoading = false;
      state.placeEvaluationDataById = action.payload;
    },

    updatePlaceEvaluationSuccess(state, action) {
      state.isLoading = false;
      state.updatePlaceEvaluationData = action.payload;
    },

    deletePlaceEvaluationSuccess(state, action) {
      state.isLoading = false;
      state.deletePlaceEvaluationData = action.payload;
    },
    userDataValueSuccess(state, action) {
      state.isLoading = false;
      state.userDataValues = action.payload;
    },

    //Outlet Start ----

    // Outlet list
    outletListSuccess(state, action) {
      state.isLoading = false;
      state.outletList = action.payload;
    },

    // get Outlet list by id
    outletListByIdSuccess(state, action) {
      state.isLoading = false;
      state.outletListById = action.payload;
    },
    // Create Outlet
    createOutletSuccess(state, action) {
      state.isLoading = false;
      state.addOutlet = action.payload;
    },
    // Update Outlet
    updateOutletSuccess(state, action) {
      state.isLoading = false;
      state.updateOutlet = action.payload;
    },
    // Delete Outlet
    deleteOutletSuccess(state, action) {
      state.isLoading = false;
      state.deleteOutlet = action.payload;
    },
    //Outlet End ----

    //News Start ----

    // News list
    newsListSuccess(state, action) {
      state.isLoading = false;
      state.newsList = action.payload;
    },

    // get News list by id
    newsListByIdSuccess(state, action) {
      state.isLoading = false;
      state.newsListById = action.payload;
    },
    // Create News
    createNewsSuccess(state, action) {
      state.isLoading = false;
      state.addNews = action.payload;
    },
    // Update News
    updateNewsSuccess(state, action) {
      state.isLoading = false;
      state.updateNews = action.payload;
    },
    // Delete News
    deleteNewsSuccess(state, action) {
      state.isLoading = false;
      state.deleteNews = action.payload;
    },
    //News End ----

    //Ticket start
    createTicketSuccess(state, action) {
      state.isLoading = false;
      state.addTicket = action.payload;
    },

    ticketListSuccess(state, action) {
      state.isLoading = false;
      state.ticketList = action.payload;
    },

    ticketListByIdSuccess(state, action) {
      state.isLoading = false;
      state.ticketListByIdData = action.payload;
    },

    updateTicketSuccess(state, action) {
      state.isLoading = false;
      state.updateTicket = action.payload;
    },

    deleteTicketSuccess(state, action) {
      state.isLoading = false;
      state.deleteTicket = action.payload;
    },
    // Ticket end

    //BusinessType start
    createBusinessTypeSuccess(state, action) {
      state.isLoading = false;
      state.addBusinessType = action.payload;
    },

    businessTypeListSuccess(state, action) {
      state.isLoading = false;
      state.businessTypeList = action.payload;
    },

    businessTypeListByIdSuccess(state, action) {
      state.isLoading = false;
      state.businessTypeListByIdData = action.payload;
    },

    updateBusinessTypeSuccess(state, action) {
      state.isLoading = false;
      state.updateBusinessType = action.payload;
    },

    deleteBusinessTypeSuccess(state, action) {
      state.isLoading = false;
      state.deleteBusinessType = action.payload;
    },
    // BusinessType end

    //BusinessRegistration start
    createBusinessRegistrationSuccess(state, action) {
      state.isLoading = false;
      state.addBusinessRegistration = action.payload;
    },

    businessRegistrationListSuccess(state, action) {
      state.isLoading = false;
      state.businessRegistrationLists = action.payload;
    },

    businessRegistrationListByIdSuccess(state, action) {
      state.isLoading = false;
      state.businessRegistrationListByIdData = action.payload;
    },

    updateBusinessRegistrationSuccess(state, action) {
      state.isLoading = false;
      state.updateBusinessRegistration = action.payload;
    },

    deleteBusinessRegistrationSuccess(state, action) {
      state.isLoading = false;
      state.deleteBusinessRegistrations = action.payload;
    },
    // BusinessRegistration end

    //Service Start ----

    // Service list
    serviceListSuccess(state, action) {
      state.isLoading = false;
      state.serviceList = action.payload;
    },

    // get Service list by id
    serviceListByIdSuccess(state, action) {
      state.isLoading = false;
      state.serviceListById = action.payload;
    },
    // Create Service
    createServiceSuccess(state, action) {
      state.isLoading = false;
      state.addService = action.payload;
    },
    // Update Service
    updateServiceSuccess(state, action) {
      state.isLoading = false;
      state.updateService = action.payload;
    },
    // Delete Service
    deleteServiceSuccess(state, action) {
      state.isLoading = false;
      state.deleteService = action.payload;
    },
    //Service End ----

    //Request Service Start ----

    createServiceRequestSuccess(state, action) {
      state.isLoading = false;
      state.createServiceRequest = action.payload;
    },
    serviceRequestListSuccess(state, action) {
      state.isLoading = false;
      state.serviceRequestList = action.payload;
    },
    serviceRequestByIdSuccess(state, action) {
      state.isLoading = false;
      state.serviceRequestDetails = action.payload;
    },
    updateServiceRequestSuccess(state, action) {
      state.isLoading = false;
      state.updateServiceRequest = action.payload;
    },
    deleteServiceRequestSuccess(state, action) {
      state.isLoading = false;
      state.deleteServiceRequest = action.payload;
    },
    //Request Service End ----

    //User start -----

    //User List
    userListSuccess(state, action) {
      state.isLoading = false;
      state.userDataList = action.payload;
    },
    // get User Data list by id
    userDataListByIdSuccess(state, action) {
      state.isLoading = false;
      state.userDataListById = action.payload;
    },
    // Create User Data
    createUserDataSuccess(state, action) {
      state.isLoading = false;
      state.addUserData = action.payload;
    },
    // Update User Data
    updateUserDataSuccess(state, action) {
      state.isLoading = false;
      state.updateUserData = action.payload;
    },
    // Delete User Data
    deleteUserDataSuccess(state, action) {
      state.isLoading = false;
      state.deleteUserData = action.payload;
    },

    //User End -----

    //Race Round Start ----
    createRaceRoundSuccess(state, action) {
      state.isLoading = false;
      state.createRaceRound = action.payload;
    },
    raceRoundListSuccess(state, action) {
      state.isLoading = false;
      state.raceRoundList = action.payload;
    },
    raceRoundByIdSuccess(state, action) {
      state.isLoading = false;
      state.raceRoundDetails = action.payload;
    },
    raceRoundListByIdSuccess(state, action) {
      state.isLoading = false;
      state.raceRoundListData = action.payload;
    },
    updateRaceRoundSuccess(state, action) {
      state.isLoading = false;
      state.updateRaceRound = action.payload;
    },
    deleteRaceRoundSuccess(state, action) {
      state.isLoading = false;
      state.deleteRaceRound = action.payload;
    },
    //Race Round End ----

    //Aboutus start

    updateAboutUsSuccess(state, action) {
      state.isLoading = false;
      state.updateAboutUsData = action.payload;
    },

    aboutUsListByIdSuccess(state, action) {
      state.isLoading = false;
      state.getByAbout = action.payload;
    },

    //aboutus End

    //Enquiry start
    enquiryListSuccess(state, action) {
      state.isLoading = false;
      state.enquiryList = action.payload;
    },

    enquiryListByIdSuccess(state, action) {
      state.isLoading = false;
      state.enquiryListByIdData = action.payload;
    },
    // Enquiry end

    //family business start
    createFamilyBusinessSuccess(state, action) {
      state.isLoading = false;
      state.familyCreated = action.payload;
    },
    listFamilyBusinessSuccess(state, action) {
      state.isLoading = false;
      state.familyList = action.payload;
    },
    listByIdFamilyBusinessSuccess(state, action) {
      state.isLoading = false;
      state.familyListById = action.payload;
    },
    updateFamilyBusinessSuccess(state, action) {
      state.isLoading = false;
      state.familyUpdate = action.payload;
    },
    deleteFamilyBusinessSuccess(state, action) {
      state.isLoading = false;
      state.familyDelete = action.payload;
    },
    //family business end

    //Women influencers start
    listWomenInfluencersSuccess(state, action) {
      state.isLoading = false;
      state.womenList = action.payload;
    },
    createWomenInfluencersSuccess(state, action) {
      state.isLoading = false;
      state.womenCreate = action.payload;
    },
    getByIdWomenInfluencersSuccess(state, action) {
      state.isLoading = false;
      state.womenListById = action.payload;
    },
    updateWomenInfluencersSuccess(state, action) {
      state.isLoading = false;
      state.womenUpdate = action.payload;
    },
    deleteWomenInfluencersSuccess(state, action) {
      state.isLoading = false;
      state.womenDelete = action.payload;
    },
    //Women influencers end

    //Safety men challenge start
    listSafetyMenChallengeSuccess(state, action) {
      state.isLoading = false;
      state.menList = action.payload;
    },
    createSafetyMenChallengeSuccess(state, action) {
      state.isLoading = false;
      state.menCreate = action.payload;
    },
    getByIdSafetyMenChallengeSuccess(state, action) {
      state.isLoading = false;
      state.menListById = action.payload;
    },
    updateSafetyMenChallengeSuccess(state, action) {
      state.isLoading = false;
      state.menUpdate = action.payload;
    },
    deleteSafetyMenChallengeSuccess(state, action) {
      state.isLoading = false;
      state.menDelete = action.payload;
    },
    //safety men challenge end

    //Career start
    listCareerSuccess(state, action) {
      state.isLoading = false;
      state.careerList = action.payload;
    },
    createCareerSuccess(state, action) {
      state.isLoading = false;
      state.careerCreate = action.payload;
    },
    getByIdCareerSuccess(state, action) {
      state.isLoading = false;
      state.careerListById = action.payload;
    },
    updateCareerSuccess(state, action) {
      state.isLoading = false;
      state.careerUpdate = action.payload;
    },
    deleteCareerSuccess(state, action) {
      state.isLoading = false;
      state.careerDelete = action.payload;
    },
    //Career end



    // SpaceAvailability start
    createSpaceAvailabilitySuccess(state, action) {
      state.isLoading = false;
      state.AddSpaceAvailability = action.payload;
    },

    spaceAvailabilityListSuccess(state, action) {
      state.isLoading = false;
      state.spaceAvailabilityList = action.payload;
    },

    spaceAvailabilityListByIdSuccess(state, action) {
      state.isLoading = false;
      state.spaceAvailabilityListByIdData = action.payload;
    },

    updateSpaceAvailabilitySuccess(state, action) {
      state.isLoading = false;
      state.updateSpaceAvailability = action.payload;
    },

    deleteSpaceAvailabilitySuccess(state, action) {
      state.isLoading = false;
      state.deleteSpaceAvailability = action.payload;
    },
    // SpaceAvailability end


    // Professional Influencer start

    createProfessionalInfluencerSuccess(state, action) {
      state.isLoading = false;
      state.AddProfessionalInfluencer = action.payload;
    },

    professionalInfluencerListSuccess(state, action) {
      state.isLoading = false;
      state.professionalInfluencerList = action.payload;
    },

    professionalInfluencerListByIdSuccess(state, action) {
      state.isLoading = false;
      state.professionalInfluencerListByIdData = action.payload;
    },

    updateProfessionalInfluencerSuccess(state, action) {
      state.isLoading = false;
      state.updateProfessionalInfluencer = action.payload;
    },

    deleteProfessionalInfluencerSuccess(state, action) {
      state.isLoading = false;
      state.deleteProfessionalInfluencer = action.payload;
    },

    // ProfessionalInfluencer end
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// Event Management start ----------------------------------------------------------------------

//Sub type Start ---
export function createRaceSubtype(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceSubType/createSubTypeRace',
        values,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.createRaceSubtypeSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createRaceSubtypeSuccess(error));
    }
  };
}

export function getSubtypeList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceSubType/raceSubTypeList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.responseData.length > 0) {
      dispatch(slice.actions.subtypeListSuccess(response.data));
      // }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSubtypeById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceSubType/raceSubTypeListById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.subtypeByIdSuccess(response.data.responseData));
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSubtype(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceSubType/updateRaceSubType',
        values,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.updateSubtypeSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateSubtypeSuccess(error));
    }
  };
}

export function deleteSubtype(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceSubType/raceSubTypeDelete',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.raceSubtypeDeleteSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//Sub type End ---

//Shop Start ---

export function createShop(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopType/shopCreate?language=en',
        values,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createShopSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createShopSuccess(error));
    }
  };
}

export function ShopList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopType/shopList?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.responseData.length > 0) {
      dispatch(slice.actions.shopListSuccess1(response.data));
      // }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function shopByRegistration(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopType/shopListById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.shopByRegistrationSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateShop(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopType/updateShop?language=en',
        values,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateShopSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateShopSuccess(error));
    }
  };
}

export function deleteShop(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopType/shopDelete?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteShopSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//Shop End ---

//Race Start -----
export function getraceList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceType/raceTypeList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length > 0) {
        dispatch(slice.actions.raceListSuccess(response.data.responseData));
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getraceListForRace() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceType/raceTypeList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.raceListForRaceSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createRaceType(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    imageUrl: values.image,
    amount: parseInt(values.amount),
    conditionDocumentUrl: values.document,
    conditionDocumentArUrl: values.documentAr,
    paymentMode: values.paymentMode,
    details: values.details,
    rulesRegulation: values.rulesRegulation,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceType/createRaceType',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.createRaceTypeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getraceListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceType/getRaceTypeListById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      // if (response.data.responseData !== undefined) {
      dispatch(slice.actions.raceListByIdSuccess(response.data.responseData));
      // }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateRaceType(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    imageUrl: values.imageUrl,
    amount: parseInt(values.amount),
    conditionDocumentUrl: values.conditionDocumentUrl,
    conditionDocumentArUrl: values.conditionDocumentArUrl,
    id: values.id,
    status: parseInt(values.status),
    command: values.command,
    details: values.details,
    rulesRegulation: values.rulesRegulation,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceType/updateRaceType',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.updateRaceTypeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteRaceType(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceType/deleteRaceType',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteRaceTypeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.deleteRaceTypeSuccess(error));
    }
  };
}

export function raceTypeByRegistration(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    raceTypeId: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceTypeByRegistration',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (
        response.data.status === 1 &&
        response.data.responseData !== undefined
      ) {
        dispatch(
          slice.actions.raceTypeByRegistrationSuccess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//Race End -----

//RaceSubType Start ----
export function getRaceSubListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceSubType/raceRoundByRaceSubType',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.raceSubListByIdSuccess(
            response.data.responseData.raceRound
          )
        );
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//RaceSubType End ----

//Registration Start -----
export function getRegisterList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    participationName: values.participationName,
    raceTypeName: values.raceTypeName,
    date: values.date,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'registration/registrationFilterByList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.responseData.length > 0) {
      dispatch(slice.actions.registerListSuccess(response.data));
      // }
      // if (response.data.status === -2) {
      //   dispatch(slice.actions.registerListSuccess(response.data.responseData));
      // }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function RegisterList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'registration/listRegistration',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData.length !== 0) {
        dispatch(slice.actions.registerListAllSuccess(response.data));
      } else {
        dispatch(slice.actions.registerListAllSuccess(response.data));
      }
      // if (response.data.status === -2) {
      //   dispatch(slice.actions.registerListSuccess(response.data.responseData));
      // }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createRegister(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    raceTypeId: values.raceTypeId,
    raceTypeName: values.raceTypeName,
    fullName: values.fullName,
    email: values.email,
    countryCode: values.countryCode,
    mobile: values.mobile,
    address: values.address,
    sportsDrivingLicenseNumber: values.sportsDrivingLicenseNumber,
    anotherEmergencyMobile: values.anotherEmergencyMobile,
    vehicleType: values.vehicleType,
    vehicleBrandName: values.vehicleBrandName,
    team: values.team,
    competitorNumber: values.competitorNumber,
    paymentType: values.paymentType,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'registration/registrationCreate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.createRegisterSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createRegisterSuccess(error));
    }
  };
}
export function getRegisterListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'registration/getRegistrationById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.registerListByIdSuccess(response.data.responseData)
        );
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// export function updateRegisters(values) {
//   const postdata = {
//     id: values.id,
//     // userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
//     userId: values.userId,
//     raceTypeId: values.raceTypeId,
//     raceTypeName: values.raceTypeName,
//     userName: values.userName,
//     participationName: values.participationName,
//     conditionDocumentUrl: values.document,
//     subType: values.subType,
//     subTypeId: values.subTypeId,
//     round: values.round,
//     roundId: values.roundId,
//     amount: parseInt(values.amount),
//     // falconName: values.falconName,
//     ringNo: values.ringNo,
//     animalName: values.animalName,
//     birthDate: values.birthDate,
//     passportNo: values.passportNo,
//     passportImage: values.passportImage,
//     image: values.image,
//     createBy: values.createBy,
//     status: parseInt(values.status),
//     command: values.command,
//     // userId1: values.userId1,
//     // userName1: values.userName1,
//   };

//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         eventApiUrl + 'updateRegistration',
//         postdata,
//         {
//           headers: {
//             authorization: authToken,
//           },
//         }
//       );

//       if (response.data.status === 1) {
//         dispatch(slice.actions.updateRegisterSuccess(response.data));
//       }
//     } catch (error) {
//       // dispatch(slice.actions.hasError(error));
//       dispatch(slice.actions.updateRegisterSuccess(error));
//     }
//   };
// }


// SpaceAvailability start
export function createSpaceAvailability(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    commercialBrandName: values.commercialBrandName,
    countryCode: values.countryCode,
    mobile: values.mobile,
    email: values.email,
    brandCategory: values.brandCategory,
    spaceRequest: parseInt(values.spaceRequest),
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/createSpaceAvailability?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createSpaceAvailabilitySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.createSpaceAvailabilitySuccess(error));
    }
  };
}

export function getSpaceAvailabilityList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/listSpaceAvailability?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length !== 0) {
        dispatch(slice.actions.spaceAvailabilityListSuccess(response.data));
      }
      if (response.data.status === -2) {
        dispatch(slice.actions.spaceAvailabilityListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSpaceAvailabilityById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/getSpaceAvailabilityById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(
          slice.actions.spaceAvailabilityListByIdSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSpaceAvailabilityData(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    commercialBrandName: values.commercialBrandName,
    id: values.id,
    email: values.email,
    countryCode: values.countryCode,
    mobile: values.mobile,
    brandCategory: values.brandCategory,
    spaceRequest: values.spaceRequest,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/updateSpaceAvailability?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateSpaceAvailabilitySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function spaceAvailabilityDelete(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/deleteSpaceAvailability?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteSpaceAvailabilitySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//SpaceAvailability End

// ProfessionalInfluencer start
export function createProfessionalInfluencer(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    fullName: values.fullName,
    surname: values.surname,
    email: values.email,
    mobile: values.mobile,
    countryCode: values.countryCode,
    instagramLink: values.instagramLink,
    facebookLink: values.facebookLink,
    snapChatLink: values.snapChatLink,
    youTubeLink: values.youTubeLink,
    tikTokLink: values.tikTokLink,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/createCareerProfessionalInfluncers?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createProfessionalInfluencerSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.createProfessionalInfluencerSuccess(error));
    }
  };
}

export function getProfessionalInfluencerList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/listProfessionalInfluncers?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length !== 0) {
        dispatch(slice.actions.professionalInfluencerListSuccess(response.data));
      }
      if (response.data.status === -2) {
        dispatch(slice.actions.professionalInfluencerListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfessionalInfluencerById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/getProfessionalInfluncerById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(
          slice.actions.professionalInfluencerListByIdSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProfessionalInfluencerData(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
    fullName: values.fullName,
    surname: values.surname,
    email: values.email,
    mobile: values.mobile,
    countryCode: values.countryCode,
    instagramLink: values.instagramLink,
    facebookLink: values.facebookLink,
    snapChatLink: values.snapChatLink,
    youTubeLink: values.youTubeLink,
    tikTokLink: values.tikTokLink,
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/updateProfessionalInfluncer?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateProfessionalInfluencerSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function professionalInfluencerDelete(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/deleteProfessionalInfluncer?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteProfessionalInfluencerSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//ProfessionalInfluencer End

export function updateRegisters(values) {
  const postdata = {
    id: values.id,
    userId: values.userId,
    raceTypeId: values.raceTypeId,
    raceTypeName: values.raceTypeName,
    fullName: values.fullName,
    email: values.email,
    countryCode: values.countryCode,
    mobile: values.mobile,
    address: values.address,
    sportsDrivingLicenseNumber: values.sportsDrivingLicenseNumber,
    anotherEmergencyMobile: values.anotherEmergencyMobile,
    vehicleType: values.vehicleType,
    vehicleBrandName: values.vehicleBrandName,
    team: values.team,
    competitorNumber: values.competitorNumber,
    paymentType: values.paymentType,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'registration/updateRegistration',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.updateRegisterSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateRegisterSuccess(error));
    }
  };
}

export function deleteRegisters(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'registration/deleteRegistration',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteRegisterSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getSubTypeList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    raceTypeId: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'registration/raceTypeByRaceSubType',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.subTypeListSuccess(response.data.responseData));
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.subTypeListSuccess(error));
    }
  };
}
//Registration End -----

//Reservation Start -----
export function getReservationList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'reservation/reservationList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length !== 0) {
        dispatch(slice.actions.reservationListSuccess(response.data));
      }
      if (response.data.responseData.length === 0) {
        dispatch(slice.actions.getPassengerBookingReportSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createReservation(values) {
  const postdata = {
    userId: values.userId,
    userName: values.userName,
    spotTypeId: values.spotTypeId,
    spotTypeName: values.spotTypeName,
    userCountryCode: values.userCountryCode,
    userMobile: values.userMobile,
    identityPhoto: values.identityPhoto,
    fromDate: values.fromDate,
    toDate: values.toDate,
    amount: parseInt(values.amount),
    sizeId: values.sizeId,
    sizeName: values.sizeName,
    // details: values.details,
    // userId1: values.userId1,
    // userName1: values.userName1,
    createBy: values.createBy,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'reservation/reservationCreate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.createReservationSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createReservationSuccess(error));
    }
  };
}
export function getReservationListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'reservation/getReservationById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.reservationListByIdSuccess(response.data.responseData)
        );
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateReservations(values) {
  const postdata = {
    id: values.id,
    // userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    userId: values.userId,
    userName: values.userName,
    spotTypeId: values.spotTypeId,
    spotTypeName: values.spotTypeName,
    userCountryCode: values.userCountryCode,
    userMobile: values.userMobile,
    identityPhoto: values.identityPhoto,
    fromDate: values.fromDate,
    toDate: values.toDate,
    amount: parseInt(values.amount),
    sizeId: values.sizeId,
    sizeName: values.sizeName,
    // details: values.details,
    // userId1: values.userId1,
    // userName1: values.userName1,
    createBy: values.createBy,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'reservation/reservationUpdate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateReservationSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteReservations(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'reservation/deleteReservation',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteReservationSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//Reservation End -----

//Spot Start -----
export function getSpotList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'spot/listSpot',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length !== 0) {
        dispatch(slice.actions.spotListSuccess(response.data));
      }
      if (response.data.responseData.length === 0) {
        dispatch(slice.actions.spotListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createSpot(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    totalAvailableReservationPerDay: parseInt(
      values.totalAvailableReservationPerDay
    ),
    totalDaysOfEvent: parseInt(values.totalDaysOfEvent),
    totalReservationLimit: values.totalReservationLimit,
    image: values.image,
    document: values.document,
    // amount: parseInt(values.amount),
    details: values.details,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'spot/createSpot',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createSpotSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createSpotSuccess(error));
    }
  };
}
export function getSpotListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'spot/getSpotById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.spotListByIdSuccess(response.data.responseData));
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateSpotDetail(values) {
  var totalCount =
    parseInt(values.totalAvailableReservationPerDay) *
    parseInt(values.totalDaysOfEvent);
  const postdata = {
    id: values.id,
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    totalAvailableReservationPerDay: parseInt(
      values.totalAvailableReservationPerDay
    ),
    totalDaysOfEvent: parseInt(values.totalDaysOfEvent),
    totalReservationLimit: totalCount,
    image: values.image,
    document: values.document,
    // amount: parseInt(values.amount),
    details: values.details,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'spot/spotUpdate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.updateSpotSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateSpotSuccess(error));
    }
  };
}
export function deleteSpot(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'spot/spotDelete',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteSpotSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//Spot End -----

//Size Start -----

export function spotSizeListBySpotId(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    spotId: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'spotSize/spotSizeBySpotId',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.responseData.length > 0) {
      dispatch(slice.actions.spotSizeListBySpotIdSuccess(response.data));
      // }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSizeList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'spotSize/spotSizeList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.responseData.length > 0) {
      dispatch(slice.actions.sizeListSuccess(response.data));
      // }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createSize(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    spotId: values.spotId,
    spotName: values.spotName,
    spotSizeList: values.spotSizeList,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'spotSize/spotSizeCreate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createSizeSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createSizeSuccess(error));
    }
  };
}
export function getSizeListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'spotSize/spotSizeById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.sizeListByIdSuccess(response.data.responseData));
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateSizeDetail(values) {
  const postdata = {
    id: values.id,
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    spotId: values.spotId,
    spotName: values.spotName,
    amount: parseInt(values.amount),
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'spotSize/updateSpotSize',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.updateSizeSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateSizeSuccess(error));
    }
  };
}
export function deleteSize(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'spotSize/deleteSpotSize',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteSizeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//Size End -----

//Vendor Start -----
export function getCompanyList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'vendor/vendorList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1 && response.data.responseData.length > 0) {
        dispatch(slice.actions.companyListSuccess(response.data.responseData));
      }
      if (response.data.status === -2) {
        dispatch(slice.actions.companyListSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      console.log('Something went wrong!');
      // dispatch(slice.actions.companyListSuccess(response.data.responseData));
    }
  };
}
export function createCompany(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    totalAvailable: values.totalAvailable,
    image: values.image,
    document: values.document,
    evaluationName: values.evaluationName,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'vendor/vendorCreate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createCompanySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCompanyListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'vendor/vendorById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // console.log('getCompanyListById  = ', response.data);
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.companyListByIdSuccess(response.data.responseData)
        );
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateCompanyDetail(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
    name: values.name,
    totalAvailable: values.totalAvailable,
    image: values.image,
    document: values.document,
    evaluationName: values.evaluationName,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'vendor/vendorUpdate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // console.log('updateCompany == ', response.data);
      if (response.data.status === 1) {
        dispatch(slice.actions.updateCompanySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteCompanyList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'vendor/vendorDelete',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteCompanySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//Vendor End -----

//Shopping Start -----
export function getShoppingList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopDetail/shopDetailList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.responseData.length > 0) {
      dispatch(slice.actions.shoppingListSuccess(response.data));
      // }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createShopping(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    shopTypeId: values.shopTypeId,
    shopTypeName: values.shopTypeName,
    countryCode: values.countryCode,
    mobile: values.mobile,
    image: values.image,
    startTime: values.startTime,
    endTime: values.endTime,
    instagramId: values.instagramId,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopDetail/shopDetailCreate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.createShoppingSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createShoppingSuccess(error));
    }
  };
}
export function getShoppingListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopDetail/shopDetailListById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.shoppingListByIdSuccess(response.data.responseData)
        );
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateShoppingDetail(values) {
  const postdata = {
    id: values.id,
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    shopTypeId: values.shopTypeId,
    shopTypeName: values.shopTypeName,
    countryCode: values.countryCode,
    mobile: values.mobile,
    image: values.image,
    startTime: values.startTime,
    endTime: values.endTime,
    instagramId: values.instagramId,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopDetail/shopDetailUpdate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.updateShoppingSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateShoppingSuccess(error));
    }
  };
}
export function deleteShoppingDetail(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopDetail/shopDetailDelete',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteShoppingSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//Shopping End -----

//Shop list Start -----
export function getShopList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopType/shopList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData.length > 0) {
        dispatch(slice.actions.shopListSuccess(response.data.responseData));
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.shopListSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//Shop list End -----

//Place Evalution list

export function placeEvalutionList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'shopDetail/shopDetailList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length > 0) {
        dispatch(slice.actions.shoppingListSuccess(response.data.responseData));
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//Place Evalution list

// Event Management end ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/manage-users');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        '/api/user/account/notifications-settings'
      );
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//Festival Guide

export function createFestivalGuide(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    documentUrl: values.documentUrl,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'festivalGuide/festivalGuideCreate?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.createFestivalGuideSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createFestivalGuideSuccess(error));
    }
  };
}

export function getGuidList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'festivalGuide/listFestivalGuide?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.responseData.length > 0) {
      dispatch(slice.actions.GuidListSuccess(response.data));
      // }
      // if (response.data.status === -2) {
      //   dispatch(dispatch(slice.actions.GuidListSuccess(response.data)));
      // }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGuideById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'festivalGuide/getFGuideListById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.guideByIdSuccess(response.data.responseData));
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateFestivalGuide(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    documentUrl: values.documentUrl,
    id: values.id,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'festivalGuide/updateFestivalGuide?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.updateGuideSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateGuideSuccess(error));
    }
  };
}

export function deleteFestivalGuide(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'festivalGuide/deleteFestivalGuide?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteGuideSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//Place EValuation

export function createPlaceEvaluation(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    amount: parseInt(values.amount),
    details: values.details,
    document: values.document,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'placeEvaluation/placeEvaluationCreate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.createPlaceEvaluationSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createPlaceEvaluationSuccess(error));
    }
  };
}

export function placeEvaluationList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'placeEvaluation/listPlaceEvaluation?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length > 0) {
        dispatch(
          slice.actions.placeEvaluationListSuccess(response.data.responseData)
        );
      }
      if (response.data.status === -2) {
        dispatch(slice.actions.getPassengerBookingReportSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlaceEvaluationById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'placeEvaluation/getPlaceEvaluationById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.PlaceEvaluationByIdSuccess(response.data.responseData)
        );
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePlaceEvaluation(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    amount: parseInt(values.amount),
    details: values.details,
    document: values.document,
    id: values.id,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'placeEvaluation/updatePlaceEvaluation?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.updatePlaceEvaluationSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePlaceEvaluation(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'placeEvaluation/placeEvaluationDelete?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deletePlaceEvaluationSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function userDataCheck(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    searchName: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'user/searchUserList?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.userDataValueSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//Outlet Start -----
export function getOutletList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'outlet/outletList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1 && response.data.responseData.length > 0) {
        dispatch(slice.actions.outletListSuccess(response.data.responseData));
      }
      if (response.data.status === -2) {
        dispatch(slice.actions.outletListSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      console.log('Something went wrong!');
      // dispatch(slice.actions.companyListSuccess(response.data.responseData));
    }
  };
}
export function createOutlet(values) {
  const postdata = {
    userId:
      values.userId1 !== '' ? values.userId1 : 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    vendorId: values.vendorId,
    vendorName: values.vendorName,
    identityPhoto: values.identityPhoto,
    licenseHolderName: values.licenseHolderName,
    outletName: values.outletName,
    outletLogo: values.outletLogo,
    instagramId: values.instagramId,
    truckImage1: values.truckImage1,
    truckImage2: values.truckImage2,
    commercialRegister: values.commercialRegister,
    isAgree: 1,
    placeEvaluationId: values.placeEvaluationId,
    placeEvaluationName: values.placeEvaluationName,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'outlet/outletCreate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createOutletSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getOutletListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'outlet/outletById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.outletListByIdSuccess(response.data.responseData)
        );
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateOutletDetail(values) {
  const postdata = {
    id: values.id,
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    userName: values.userName,
    userMobile: values.userMobile,
    userCountryCode: values.userCountryCode,
    userEmail: values.userEmail,
    vendorId: values.vendorId,
    vendorName: values.vendorName,
    identityPhoto: values.identityPhoto,
    licenseHolderName: values.licenseHolderName,
    outletName: values.outletName,
    outletLogo: values.outletLogo,
    instagramId: values.instagramId,
    truckImage1: values.truckImage1,
    truckImage2: values.truckImage2,
    commercialRegister: values.commercialRegister,
    isAgree: 1,
    placeEvaluationId: values.placeEvaluationId,
    placeEvaluationName: values.placeEvaluationName,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'outlet/outletUpdate',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // console.log('updateOutlet == ', response.data);
      if (response.data.status === 1) {
        dispatch(slice.actions.updateOutletSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteOutletList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'outlet/deleteOutlet',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteOutletSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//Outlet End -----

//News Start ----

export function createNews(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    title: values.title,
    image: values.image,
    description: values.description,
    priority: values.priority,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'news/createNews?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createNewsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.createNewsSuccess(error));
    }
  };
}

export function getNewsList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'news/listNews?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.responseData.length > 0) {
      dispatch(slice.actions.newsListSuccess(response.data));
      // }
      // if (response.data.status === -2) {
      //   dispatch(dispatch(slice.actions.newsListSuccess(response.data)));
      // }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNewsListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'news/newsById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.newsListByIdSuccess(response.data.responseData));
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateNewsList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    title: values.title,
    image: values.image,
    description: values.description,
    priority: values.priority,
    id: values.id,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'news/newsUpdate?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateNewsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteNewsList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'news/deleteNews?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteNewsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//News End -----

// Ticket start
export function createTicket(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    image: values.image,
    amount: parseInt(values.amount),
    productType: values.productType,
    SKU: parseInt(values.SKU),
    limit: parseInt(values.limit),
    details: values.details,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'ticket/createTicket?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createTicketSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.createTicketSuccess(error));
    }
  };
}

export function getTicketList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'ticket/listTicket?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length !== 0) {
        dispatch(slice.actions.ticketListSuccess(response.data));
      }
      if (response.data.status === -2) {
        dispatch(slice.actions.ticketListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTicketListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'ticket/ticketById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(
          slice.actions.ticketListByIdSuccess(response.data.responseData)
        );
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.ticketListByIdSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTicketList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    image: values.image,
    id: values.id,
    name: values.name,
    amount: parseInt(values.amount),
    productType: values.productType,
    SKU: parseInt(values.SKU),
    limit: parseInt(values.limit),
    status: parseInt(values.status),
    command: values.command,
    details: values.details,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'ticket/updateTicket?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateTicketSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteTicketList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'ticket/deleteTicket?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteTicketSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//Ticket End

// Enquiry start

export function getEnquiryList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'common/inquiryList?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.responseData.length > 0) {
      dispatch(slice.actions.enquiryListSuccess(response.data));
      // }
      // if (response.data.status === -2) {
      //   dispatch(slice.actions.enquiryListSuccess(response.data));
      // }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEnquiryListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'common/inquiryDetailById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(
          slice.actions.enquiryListByIdSuccess(response.data.responseData)
        );
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.enquiryListByIdSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Enquiry End

// BusinessType start
export function createBusinessType(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    imageUrl: values.imageUrl,
    documentUrl: values.documentUrl,
    aboutEn: values.aboutEn,
    aboutAr: values.aboutAr,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/createBusinessType?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createBusinessTypeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.createBusinessTypeSuccess(error));
    }
  };
}

export function getBusinessTypeList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/businessTypeByList?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.responseData.length > 0) {
      dispatch(slice.actions.businessTypeListSuccess(response.data));
      // }
      // if (response.data.status === -2) {
      //   dispatch(slice.actions.businessTypeListSuccess(response.data));
      // }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBusinessTypeListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/businessTypeById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(
          slice.actions.businessTypeListByIdSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBusinessTypeList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
    name: values.name,
    imageUrl: values.imageUrl,
    amount: parseInt(values.amount),
    status: parseInt(values.status),
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/updateBusinessType?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateBusinessTypeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteBusinessTypeList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/deleteBusinessType?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.status === 1) {
      dispatch(slice.actions.deleteBusinessTypeSuccess(response.data.status));
      // }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//BusinessType End

// BusinessRegistration start
export function createBusinessRegistration(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    imageUrl: values.imageUrl,
    // amount: parseInt(values.amount)
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'createBusinessRegistration?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(
          slice.actions.createBusinessRegistrationSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.createBusinessRegistrationSuccess(error));
    }
  };
}

export function getBusinessRegistrationList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/listBusinessRegistration?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length > 0) {
        dispatch(
          slice.actions.businessRegistrationListSuccess(
            response.data.responseData
          )
        );
      }
      if (response.data.status === -2) {
        dispatch(slice.actions.businessRegistrationListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBusinessRegistrationListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/businessRegistrationById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(
          slice.actions.businessRegistrationListByIdSuccess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBusinessRegistrationList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
    name: values.name,
    imageUrl: values.imageUrl,
    amount: parseInt(values.amount),
    status: parseInt(values.status),
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/updateBusinessRegistration?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(
          slice.actions.updateBusinessRegistrationSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteBusinessRegistration(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/deleteBusinessRegistration?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(
          slice.actions.deleteBusinessRegistrationSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//BusinessRegistration End

export function createService(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    image: values.image,
    document: values.document,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'service/serviceCreate?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createServiceSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createServiceSuccess(error));
    }
  };
}

export function getServiceList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'service/serviceList?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length > 0) {
        dispatch(slice.actions.serviceListSuccess(response.data.responseData));
      }
      if (response.data.status === -2) {
        dispatch(dispatch(slice.actions.serviceListSuccess(response.data)));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.serviceListSuccess(error));
    }
  };
}

export function getServiceListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'service/serviceListById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.serviceListByIdSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.serviceListByIdSuccess(error));
    }
  };
}

export function updateServiceList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    image: values.image,
    document: values.document,
    id: values.id,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'service/updateService?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateServiceSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateServiceSuccess(error));
    }
  };
}

export function deleteServiceList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'service/deleteService?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteServiceSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.deleteServiceSuccess(error));
    }
  };
}

//Services End -----

//Services Request Start ----

export function createServiceRequestDetail(values) {
  const postdata = {
    // userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    userId:
      values.userId1 !== '' ? values.userId1 : 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    userName: values.userName,
    userMobile: values.userMobile,
    userCountryCode: values.userCountryCode,
    serviceTypeId: values.serviceTypeId,
    serviceTypeName: values.serviceTypeName,
    campId: values.campId,
    date: values.date,
    isAgree: values.isAgree,
    latitude: '',
    longitude: '',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'serviceRequest/createServiceRequest?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createServiceRequestSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createServiceRequestSuccess(error));
    }
  };
}

export function getServiceRequestList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'serviceRequest/serviceRequestList?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData.length > 0) {
        dispatch(
          slice.actions.serviceRequestListSuccess(response.data.responseData)
        );
      }
      if (response.data.status === -2) {
        dispatch(
          dispatch(slice.actions.serviceRequestListSuccess(response.data))
        );
      }
    } catch (error) {
      dispatch(slice.actions.serviceRequestListSuccess(error));
    }
  };
}

export function getServiceRequestById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'serviceRequest/serviceRequestById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.serviceRequestByIdSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.serviceRequestByIdSuccess(error));
    }
  };
}

export function updateServiceRequestDetail(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    userName: values.userName,
    userMobile: values.userMobile,
    userCountryCode: values.userCountryCode,
    serviceTypeId: values.serviceTypeId,
    serviceTypeName: values.serviceTypeName,
    campId: values.campId,
    date: values.date,
    latitude: '',
    longitude: '',
    isAgree: values.isAgree,
    id: values.id,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'serviceRequest/serviceRequestUpdate?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateServiceRequestSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.updateServiceRequestSuccess(error));
    }
  };
}

export function deleteServiceRequestById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'serviceRequest/deleteServiceRequest?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteServiceRequestSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.deleteServiceRequestSuccess(error));
    }
  };
}

//Request Services End -----

//Users Start ---
export function getUserDataList(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    mobile: values.mobile,
    email: values.email,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'user/filterByUserList',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length !== 0) {
        dispatch(slice.actions.userListSuccess(response.data));
      }
      if (response.data.responseData.length === 0) {
        dispatch(slice.actions.userListSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.userListSuccess(error));
    }
  };
}

export function createUserDetail(values) {
  const postdata = {
    name: values.name,
    countryCode: values.countryCode,
    mobile: values.mobile,
    photoUrl: values.photoUrl,
    licenseNumber: values.licenseNumber,
    dateOfBirth: values.dateOfBirth !== '' ? values.dateOfBirth : null,
    country: values.country !== '' ? values.country : null,
    city: values.city !== '' ? values.city : null,
    address: values.address !== '' ? values.address : null,
    deviceType: '3',
    activeStatus: values.activeStatus,
    email: values.email,
  };
  console.log('postdata == ', postdata);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'user/createUser?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // console.log('response == ', response);
      if (response.data.status === 1) {
        dispatch(slice.actions.createUserDataSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.createUserDataSuccess(error));
    }
  };
}
export function getUserById(values) {
  const postdata = {
    userId: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'user/profileUser?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.userDataListByIdSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.userDataListByIdSuccess(error));
    }
  };
}

export function updateUser(values) {
  const postdata = {
    userId: values.id,
    id: values.id,
    name: values.name,
    countryCode: values.countryCode,
    mobile: values.mobile,
    password: null,
    confirmPassword: null,
    photoUrl: values.photoUrl,
    licenseNumber: values.licenseNumber,
    dateOfBirth: values.dateOfBirth !== '' ? values.dateOfBirth : null,
    country: values.country !== '' ? values.country : null,
    city: values.city !== '' ? values.city : null,
    address: values.address !== '' ? values.address : null,
    deviceToken: null,
    deviceId: null,
    deviceType: '3',
    email: values.email,
    activeStatus: values.activeStatus,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'user/updateProfile?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateUserDataSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateUserDataSuccess(error));
    }
  };
}

export function deleteUser(values) {
  const postdata = {
    userId: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'deleteUser?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteUserDataSuccess(response.data));
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.deleteUserDataSuccess(error));
    }
  };
}

//Users End ---

// Booking start
export function getBookingList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'booking/listBooking?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.status === 1) {
      dispatch(slice.actions.bookingListSuccess(response.data));
      // }
    } catch (error) {
      dispatch(slice.actions.bookingListSuccess(error));
    }
  };
}

export function getBookingDetails(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'booking/getBookingById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(
          slice.actions.bookingDetailsByIdSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.bookingDetailsByIdSuccess(error));
    }
  };
}

export function updateBookingDetails(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
    status: parseInt(values.status),
    command: values.command,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'booking/bookingUpdate?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateBookingDetailsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.updateBookingDetailsSuccess(error));
    }
  };
}
// Booking end

//Race Round Start ----

export function createRaceRoundDetail(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    raceTypeId: values.raceTypeId,
    raceTypeName: values.raceTypeName,
    raceSubTypeId: values.raceSubTypeId,
    raceSubTypeName: values.raceSubTypeName,
    raceRoundList: values.raceRoundList,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceRound/createRaceRound?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.status === 1) {
      dispatch(slice.actions.createRaceRoundSuccess(response.data));
      // }
    } catch (error) {
      dispatch(slice.actions.createRaceRoundSuccess(error));
    }
  };
}

export function getRaceRoundList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceRound/listRaceRound?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // console.log('response == ', response);
      // if (response.data.responseData.length > 0) {
      dispatch(slice.actions.raceRoundListSuccess(response.data));
      // }
      // if (response.data.status === -2) {
      //   dispatch(dispatch(slice.actions.raceRoundListSuccess(response.data)));
      // }
    } catch (error) {
      dispatch(slice.actions.raceRoundListSuccess(error));
    }
  };
}

export function getRaceRoundById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceRound/raceRoundById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.raceRoundByIdSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.raceRoundByIdSuccess(error));
    }
  };
}

export function updateRaceRoundDetail(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    raceTypeId: values.raceTypeId,
    raceTypeName: values.raceTypeName,
    raceSubTypeId: values.raceSubTypeId,
    raceSubTypeName: values.raceSubTypeName,
    name: values.name,
    amount: parseInt(values.amount),
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceRound/updateRaceRound?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateRaceRoundSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.updateRaceRoundSuccess(error));
    }
  };
}

export function deleteRaceRoundById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceRound/deleteRaceRound?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteRaceRoundSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.deleteRaceRoundSuccess(error));
    }
  };
}

export function getRaceRoundListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    raceSubTypeId: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'raceRound/raceSubTypeIdByRaceRoundList?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.raceRoundListByIdSuccess(response.data.responseData)
        );
      }
      //  if (response.data.status === 2) {
      //    dispatch(
      //      slice.actions.getPassengerBookingReportSuccess(response.data)
      //    );
      //  }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//Race Round End -----

// Transaction start
export function getTransactionList() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'payment/transactionAllList?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      // if (response.data.status === 1) {
      dispatch(slice.actions.transactionListSuccess(response.data));
      // }
    } catch (error) {
      dispatch(slice.actions.transactionListSuccess(error));
    }
  };
}

export function getTransactionDetails(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'payment/getTransactionDetails?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(
          slice.actions.transactionDetailsByIdSuccess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.transactionDetailsByIdSuccess(error));
    }
  };
}
// Transaction end

//About Us start

export function updateAboutUs(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    content: values.content,
    contentAr: values.contentAr,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'common/aboutUsUpdate?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateAboutUsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.updateAboutUsSuccess(error));
    }
  };
}

export function getAboutListById(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'common/aboutUsDetailById?language=en',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.aboutUsListByIdSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//About Us End

// Family Business start

export function createFamilyBusiness(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    brandName: values.brandName,
    ownerName: values.ownerName,
    email: values.email,
    countryCode: values.countryCode,
    mobile: values.mobile,
    instagramLink: values.instagramLink,
    websiteLink: values.websiteLink,
    whatsAppBusinessLink: values.whatsAppBusinessLink,
    productDetails: values.productDetails,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/familyBuinessRegistration',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(slice.actions.createFamilyBusinessSuccess(response.data));
      } else {
        dispatch(slice.actions.createFamilyBusinessSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.createFamilyBusinessSuccess(error));
    }
  };
}

export function listFamilyBusiness() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/listBusinessRegistration',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.listFamilyBusinessSuccess(response.data));
      } else {
        dispatch(slice.actions.listFamilyBusinessSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.listFamilyBusinessSuccess(error));
    }
  };
}

export function getByIdFamilyBusiness(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/businessRegistrationById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length !== 0) {
        dispatch(
          slice.actions.listByIdFamilyBusinessSuccess(
            response.data.responseData
          )
        );
      } else {
        dispatch(
          slice.actions.listByIdFamilyBusinessSuccess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.listByIdFamilyBusinessSuccess(error));
    }
  };
}

export function updateFamilyBusiness(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
    brandName: values.brandName,
    ownerName: values.ownerName,
    email: values.email,
    countryCode: values.countryCode,
    mobile: values.mobile,
    instagramLink: values.instagramLink,
    websiteLink: values.websiteLink,
    whatsAppBusinessLink: values.whatsAppBusinessLink,
    productDetails: values.productDetails,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/updateBusinessRegistration',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateFamilyBusinessSuccess(response.data));
      } else {
        dispatch(slice.actions.updateFamilyBusinessSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.updateFamilyBusinessSuccess(error));
    }
  };
}

export function deleteFamilyBusiness(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'familyBusiness/deleteBusinessRegistration',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteFamilyBusinessSuccess(response.data));
      } else {
        dispatch(slice.actions.deleteFamilyBusinessSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.deleteFamilyBusinessSuccess(error));
    }
  };
}

// Family Business end

// Women Influencers start

export function listWomenInfluencers() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/listWomenInfluencers',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.listWomenInfluencersSuccess(response.data));
      } else {
        dispatch(slice.actions.listWomenInfluencersSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.listWomenInfluencersSuccess(error));
    }
  };
}

export function createWomenInfluencers(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    surname: values.surname,
    countryCode: values.countryCode,
    mobile: values.mobile,
    email: values.email,
    competitorCategory: values.competitorCategory,
    snapchat: values.snapchat,
    instagram: values.instagram,
    youTube: values.youTube,
    facebook: values.facebook,
    tikTok: values.tikTok,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/womenInfluencers',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createWomenInfluencersSuccess(response.data));
      } else {
        dispatch(slice.actions.createWomenInfluencersSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.createWomenInfluencersSuccess(error));
    }
  };
}

export function getByIdWomenInfluencers(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/getWomenInfluencersById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length !== 0) {
        dispatch(
          slice.actions.getByIdWomenInfluencersSuccess(
            response.data.responseData
          )
        );
      } else {
        dispatch(
          slice.actions.getByIdWomenInfluencersSuccess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.getByIdWomenInfluencersSuccess(error));
    }
  };
}

export function updateWomenInfluencers(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
    name: values.name,
    surname: values.surname,
    countryCode: values.countryCode,
    mobile: values.mobile,
    email: values.email,
    competitorCategory: values.competitorCategory,
    snapchat: values.snapchat,
    instagram: values.instagram,
    youTube: values.youTube,
    facebook: values.facebook,
    tikTok: values.tikTok,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/updateWomenInfluencers',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateWomenInfluencersSuccess(response.data));
      } else {
        dispatch(slice.actions.updateWomenInfluencersSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.updateWomenInfluencersSuccess(error));
    }
  };
}

export function deleteWomenInfluencers(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/deleteWomenInfluencers',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteWomenInfluencersSuccess(response.data));
      } else {
        dispatch(slice.actions.deleteWomenInfluencersSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.deleteWomenInfluencersSuccess(error));
    }
  };
}

// Women Influencers end

// Safety men challenge start

export function listSafetyMenChallenge() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'safetyMenChallenge/listSafetyMenChallenge',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.listSafetyMenChallengeSuccess(response.data));
      } else {
        dispatch(slice.actions.listSafetyMenChallengeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.listSafetyMenChallengeSuccess(error));
    }
  };
}

export function createSafetyMenChallenge(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    fullName: values.fullName,
    countryCode: values.countryCode,
    mobile: values.mobile,
    email: values.email,
    employer: values.employer,
    nameOfEmployer: values.snapcnameOfEmployerhat,
    noObjectionFromEmployer: values.noObjectionFromEmployer,
    entityName: values.entityName,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'safetyMenChallenge/createSafetyMenChallenge',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createSafetyMenChallengeSuccess(response.data));
      } else {
        dispatch(slice.actions.createSafetyMenChallengeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.createSafetyMenChallengeSuccess(error));
    }
  };
}

export function getByIdSafetyMenChallenge(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'safetyMenChallenge/getSafetyMenChallengeById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length !== 0) {
        dispatch(
          slice.actions.getByIdSafetyMenChallengeSuccess(
            response.data.responseData
          )
        );
      } else {
        dispatch(
          slice.actions.getByIdSafetyMenChallengeSuccess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.getByIdSafetyMenChallengeSuccess(error));
    }
  };
}

export function updateSafetyMenChallenge(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
    fullName: values.fullName,
    countryCode: values.countryCode,
    mobile: values.mobile,
    email: values.email,
    employer: values.employer,
    noObjectionFromEmployer: values.noObjectionFromEmployer,
    entityName: values.entityName,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'safetyMenChallenge/updateSafetyMenChallenge',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateSafetyMenChallengeSuccess(response.data));
      } else {
        dispatch(slice.actions.updateSafetyMenChallengeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.updateSafetyMenChallengeSuccess(error));
    }
  };
}

export function deleteSafetyMenChallenge(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'safetyMenChallenge/deleteSafetyMenChallenge',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteSafetyMenChallengeSuccess(response.data));
      } else {
        dispatch(slice.actions.deleteSafetyMenChallengeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.deleteSafetyMenChallengeSuccess(error));
    }
  };
}

// Safety men challenge end

//Career start

export function listCareer() {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/listPublicFigures',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.listCareerSuccess(response.data));
      } else {
        dispatch(slice.actions.listCareerSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.listCareerSuccess(error));
    }
  };
}

export function createCareer(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    name: values.name,
    countryCode: values.countryCode,
    mobile: values.mobile,
    email: values.email,
    documentUrl: values.documentUrl,
    crowdOrganizers: values.crowdOrganizers,
    ticketSalesRepresentatives: values.ticketSalesRepresentatives,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/createCareerPublicFigures',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.createCareerSuccess(response.data));
      } else {
        dispatch(slice.actions.createCareerSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.createCareerSuccess(error));
    }
  };
}

export function getByIdCareer(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/getPublicFiguresById',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.responseData.length !== 0) {
        dispatch(
          slice.actions.getByIdCareerSuccess(response.data.responseData)
        );
      } else {
        dispatch(
          slice.actions.getByIdCareerSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.getByIdCareerSuccess(error));
    }
  };
}

export function updateCareer(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
    name: values.name,
    countryCode: values.countryCode,
    mobile: values.mobile,
    email: values.email,
    documentUrl: values.documentUrl,
    crowdOrganizers: values.crowdOrganizers,
    ticketSalesRepresentatives: values.ticketSalesRepresentatives,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/updatePublicFigures',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.updateCareerSuccess(response.data));
      } else {
        dispatch(slice.actions.updateCareerSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.updateCareerSuccess(error));
    }
  };
}

export function deleteCareer(values) {
  const postdata = {
    userId: 'CSorlh4zeTXmpjfXmZn64cuibtQ2',
    id: values.id,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        eventApiUrl + 'career/deletePublicFigures',
        postdata,
        {
          headers: {
            authorization: authToken,
          },
        }
      );
      if (response.data.status === 1) {
        dispatch(slice.actions.deleteCareerSuccess(response.data));
      } else {
        dispatch(slice.actions.deleteCareerSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.deleteCareerSuccess(error));
    }
  };
}

//Career end
