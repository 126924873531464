// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app',
  docs: '/docs',
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify'),
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
};

export const PATH_HOME = {
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  dashboard: ROOTS.app,
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
    ecommerce: path(ROOTS.app, '/dashboard/ecommerce'),
    analytics: path(ROOTS.app, '/dashboard/analytics'),
  },
  race: {
    root: path(ROOTS.app, '/event/race'),
    race: path(ROOTS.app, '/event/race'),
    addRaceType: path(ROOTS.app, '/event/race/addRaceType'),
    editRaceType: path(ROOTS.app, '/event/race/editRaceType/:id'),
    viewRaceType: path(ROOTS.app, '/event/race/viewRaceType/:id'),
    register: path(ROOTS.app, '/event/race/register'),
    addRegister: path(ROOTS.app, '/event/race/addRegister'),
    editRegister: path(ROOTS.app, '/event/race/editRegister/:id'),
    viewRegister: path(ROOTS.app, '/event/race/viewRegister/:id'),
    subtype: path(ROOTS.app, '/event/race/subtype'),
    addSubtype: path(ROOTS.app, '/event/race/addSubtype'),
    editSubtype: path(ROOTS.app, '/event/race/editSubtype/:id'),
    viewSubtype: path(ROOTS.app, '/event/race/viewSubtype/:id'),
    raceRound: path(ROOTS.app, '/event/race/raceRound'),
    addRaceRound: path(ROOTS.app, '/event/race/addRaceRound'),
    editRaceRound: path(ROOTS.app, '/event/race/editRaceRound/:id'),
  },
  reservation: {
    root: path(ROOTS.app, '/event/reservation'),
    spot: path(ROOTS.app, '/event/reservation/spotList'),
    addSpot: path(ROOTS.app, '/event/reservation/addSpot'),
    editSpot: path(ROOTS.app, '/event/reservation/editSpot/:id'),
    viewSpot: path(ROOTS.app, '/event/reservation/viewSpot/:id'),
    reservation: path(ROOTS.app, '/event/reservation'),
    addReservation: path(ROOTS.app, '/event/reservation/addReservation'),
    editReservation: path(ROOTS.app, '/event/reservation/editReservation/:id'),
    viewReservation: path(ROOTS.app, '/event/reservation/viewReservation/:id'),
    size: path(ROOTS.app, '/event/reservation/sizeList'),
    addSize: path(ROOTS.app, '/event/reservation/addSize'),
    editSize: path(ROOTS.app, '/event/reservation/editSize/:id'),
  },
  shop: {
    root: path(ROOTS.app, '/event/shop'),
    shop: path(ROOTS.app, '/event/shop'),
    addShop: path(ROOTS.app, '/event/shop/addShop'),
    editShop: path(ROOTS.app, '/event/shop/editShop/:id'),
    viewShop: path(ROOTS.app, '/event/shop/viewShop/:id'),
    shopping: path(ROOTS.app, '/event/shop/shopping'),
    addShopping: path(ROOTS.app, '/event/shop/addShopping'),
    editShopping: path(ROOTS.app, '/event/shop/editShopping/:id'),
    viewShopping: path(ROOTS.app, '/event/shop/viewShopping/:id'),
  },
  company: {
    root: path(ROOTS.app, '/event/company'),
    company: path(ROOTS.app, '/event/company/vendorList'),
    addCompany: path(ROOTS.app, '/event/company/addVendor'),
    editCompany: path(ROOTS.app, '/event/company/editVendor/:id'),
    viewCompany: path(ROOTS.app, '/event/company/viewVendor/:id'),
    outlet: path(ROOTS.app, '/event/company/outletList'),
    addOutlet: path(ROOTS.app, '/event/company/addOutlet'),
    editOutlet: path(ROOTS.app, '/event/company/editOutlet/:id'),
    viewOutlet: path(ROOTS.app, '/event/company/viewOutlet/:id'),
    placeEvaluation: path(ROOTS.app, '/event/company/placeEvaluation'),
    addPlaceEvaluation: path(ROOTS.app, '/event/company/addPlaceEvaluation'),
    editPlaceEvaluation: path(
      ROOTS.app,
      '/event/company/editPlaceEvaluation/:id'
    ),
    viewPlaceEvaluation: path(
      ROOTS.app,
      '/event/company/viewPlaceEvaluation/:id'
    ),
  },
  service: {
    root: path(ROOTS.app, '/event/service'),
    service: path(ROOTS.app, '/event/service'),
    addService: path(ROOTS.app, '/event/service/addService'),
    editService: path(ROOTS.app, '/event/service/editService/:id'),
    viewService: path(ROOTS.app, '/event/service/viewService/:id'),
    requestService: path(ROOTS.app, '/event/service/serviceRequest'),
    addRequestService: path(ROOTS.app, '/event/service/addServiceRequest'),
    editRequestService: path(
      ROOTS.app,
      '/event/service/editServiceRequest/:id'
    ),
    viewRequestService: path(
      ROOTS.app,
      '/event/service/viewServiceRequest/:id'
    ),
  },

  // businessType
  businessType: {
    root: path(ROOTS.app, '/event/businessType'),
    businessType: path(ROOTS.app, '/event/businessType'),
    addBusinessType: path(ROOTS.app, '/event/businessType/addBusinessType'),
    editBusinessType: path(
      ROOTS.app,
      '/event/businessType/editBusinessType/:id'
    ),
    viewBusinessType: path(
      ROOTS.app,
      '/event/businessType/viewBusinessType/:id'
    ),
    businessRegistration: path(
      ROOTS.app,
      '/event/businessType/businessRegistration'
    ),
    addBusinessRegistration: path(
      ROOTS.app,
      '/event/businessType/addBusinessRegistration'
    ),
    editBusinessRegistration: path(
      ROOTS.app,
      '/event/businessType/editBusinessRegistration/:id'
    ),
    viewBusinessRegistration: path(
      ROOTS.app,
      '/event/businessType/viewBusinessRegistration/:id'
    ),
  },

  app: {
    itemOne: path(ROOTS.app, '/item-one'),
    itemTwo: path(ROOTS.app, '/item-two'),
    itemThree: path(ROOTS.app, '/item-three'),
    form: path(ROOTS.app, '/form'),
    form1: path(ROOTS.app, '/form1'),
    team: path(ROOTS.app, '/team'),
    add_team: path(ROOTS.app, '/add_team'),
    edit_team: path(ROOTS.app, '/edit_team/:id'),
    customer: path(ROOTS.app, '/customer'),
    add_customer: path(ROOTS.app, '/add_customer'),
    edit_customer: path(ROOTS.app, '/edit_customer/:id'),
    add_visit: path(ROOTS.app, '/add_visit/:id'),
    edit_visit: path(ROOTS.app, '/edit_visit/:id'),
    add_notes: path(ROOTS.app, '/add_notes/:id'),
    edit_notes: path(ROOTS.app, '/edit_notes/:id'),
    expense: path(ROOTS.app, '/expense'),
    add_expense: path(ROOTS.app, '/add_expense'),
    edit_expense: path(ROOTS.app, '/edit_expense/:id'),
    expenses: path(ROOTS.app, '/report/expenses'),
    visits: path(ROOTS.app, '/report/visits'),
    add_visits: path(ROOTS.app, '/report/add_visits'),
    edit_visits: path(ROOTS.app, '/report/edit_visits/:id'),

    // Event Management Start---------------------

    // Race
    // race: path(ROOTS.app, '/event/raceList'),
    // addRaceType: path(ROOTS.app, '/event/race/addRaceType'),
    // editRaceType: path(ROOTS.app, '/event/race/editRaceType/:id'),

    //Registration
    // register: path(ROOTS.app, '/event/register'),
    // addRegister: path(ROOTS.app, '/event/addRegister'),
    // editRegister: path(ROOTS.app, '/event/editRegister/:id'),

    //Reservation
    // reservation: path(ROOTS.app, '/event/reservation'),
    // addReservation: path(ROOTS.app, '/event/addReservation'),
    // editReservation: path(ROOTS.app, '/event/editReservation/:id'),

    //Shopping
    // shopping: path(ROOTS.app, '/event/shopping'),
    // addShopping: path(ROOTS.app, '/event/addShopping'),
    // editShopping: path(ROOTS.app, '/event/editShopping/:id'),

    //Spot
    // spot: path(ROOTS.app, '/event/spotList'),
    // addSpot: path(ROOTS.app, '/event/addSpot'),
    // editSpot: path(ROOTS.app, '/event/editSpot/:id'),

    //Subtype
    // subtype: path(ROOTS.app, '/event/Subtype'),
    // addSubtype: path(ROOTS.app, '/event/addSubtype'),
    // editSubtype: path(ROOTS.app, '/event/editSubtype/:id'),

    //Shop
    // shop: path(ROOTS.app, '/event/Shop'),
    // addShop: path(ROOTS.app, '/event/addShop'),
    // editShop: path(ROOTS.app, '/event/editShop/:id'),

    //Company
    // company: path(ROOTS.app, '/event/companyList'),
    // addCompany: path(ROOTS.app, '/event/addCompany'),
    // editCompany: path(ROOTS.app, '/event/editCompany/:id'),

    //Guide
    guide: path(ROOTS.app, '/event/festivalGuide'),
    addGuide: path(ROOTS.app, '/event/festivalGuide/addFestivalGuide'),
    editGuide: path(ROOTS.app, '/event/festivalGuide/editFestivalGuide/:id'),
    viewGuide: path(ROOTS.app, '/event/festivalGuide/viewFestivalGuide/:id'),

    //Place Evaluation
    // placeEvaluation: path(ROOTS.app, '/event/PlaceEvaluation'),
    // addPlaceEvaluation: path(ROOTS.app, '/event/AddPlaceEvaluation'),
    // editPlaceEvaluation: path(ROOTS.app, '/event/EditPlaceEvaluation/:id'),

    //User
    // user: path(ROOTS.app, '/event/User'),
    // addUser: path(ROOTS.app, '/event/AddUser'),
    // editUser: path(ROOTS.app, '/event/EditUser/:id'),

    //Outlet
    // outlet: path(ROOTS.app, '/event/outletList'),
    // addOutlet: path(ROOTS.app, '/event/addOutlet'),
    // editOutlet: path(ROOTS.app, '/event/editOutlet/:id'),

    // News
    news: path(ROOTS.app, '/event/news'),
    addNews: path(ROOTS.app, '/event/news/addNews'),
    editNews: path(ROOTS.app, '/event/news/editNews/:id'),
    viewNews: path(ROOTS.app, '/event/news/viewNews/:id'),

    // Service
    // service: path(ROOTS.app, '/event/Service'),
    // addService: path(ROOTS.app, '/event/AddService'),
    // editService: path(ROOTS.app, '/event/EditService/:id'),

    // Ticket
    ticket: path(ROOTS.app, '/event/ticket'),
    addTicket: path(ROOTS.app, '/event/ticket/addTicket'),
    editTicket: path(ROOTS.app, '/event/ticket/editTicket/:id'),
    viewTicket: path(ROOTS.app, '/event/ticket/viewTicket/:id'),

    // SpaceAvailability
    spaceAvailability: path(ROOTS.app, '/event/spaceAvailability'),
    addSpaceAvailability: path(ROOTS.app, '/event/spaceAvailability/addSpaceAvailability'),
    editSpaceAvailability: path(ROOTS.app, '/event/spaceAvailability/editSpaceAvailability/:id'),
    viewSpaceAvailability: path(ROOTS.app, '/event/spaceAvailability/viewSpaceAvailability/:id'),

    // ProfessionalInfluencer
    professionalInfluencer: path(ROOTS.app, '/event/professionalInfluencer'),
    addProfessionalInfluencer: path(ROOTS.app, '/event/professionalInfluencer/addProfessionalInfluencer'),
    editProfessionalInfluencer: path(ROOTS.app, '/event/professionalInfluencer/editProfessionalInfluencer/:id'),
    viewProfessionalInfluencer: path(ROOTS.app, '/event/professionalInfluencer/viewProfessionalInfluencer/:id'),

    // Enquiry
    enquiry: path(ROOTS.app, '/event/enquiry'),
    viewEnquiry: path(ROOTS.app, '/event/enquiry/viewEnquiry/:id'),

    //Service
    // service: path(ROOTS.app, '/event/Service'),
    // addService: path(ROOTS.app, '/event/AddService'),
    // editService: path(ROOTS.app, '/event/EditService/:id'),

    //Request Service
    // requestService: path(ROOTS.app, '/event/serviceRequest'),
    // addRequestService: path(ROOTS.app, '/event/addServiceRequest'),
    // editRequestService: path(ROOTS.app, '/event/editServiceRequest/:id'),

    //User
    user: path(ROOTS.app, '/event/user'),
    addUser: path(ROOTS.app, '/event/user/addUser'),
    editUser: path(ROOTS.app, '/event/user/editUser/:id'),
    viewUser: path(ROOTS.app, '/event/user/viewUser/:id'),

    //Family Business
    family: path(ROOTS.app, '/event/family'),
    addFamily: path(ROOTS.app, '/event/family/addFamily'),
    editFamily: path(ROOTS.app, '/event/family/editFamily/:id'),
    viewFamily: path(ROOTS.app, '/event/family/viewFamily/:id'),

    //women influencers
    women: path(ROOTS.app, '/event/women'),
    addWomen: path(ROOTS.app, '/event/women/addWomen'),
    editWomen: path(ROOTS.app, '/event/women/editWomen/:id'),
    viewWomen: path(ROOTS.app, '/event/women/viewWomen/:id'),

    //Safety men challenge
    men: path(ROOTS.app, '/event/men'),
    addMen: path(ROOTS.app, '/event/men/addMen'),
    editMen: path(ROOTS.app, '/event/men/editMen/:id'),
    viewMen: path(ROOTS.app, '/event/men/viewMen/:id'),

    //Career
    career: path(ROOTS.app, '/event/career'),
    addCareer: path(ROOTS.app, '/event/career/addCareer'),
    editCareer: path(ROOTS.app, '/event/career/editCareer/:id'),
    viewCareer: path(ROOTS.app, '/event/career/viewCareer/:id'),

    //Bookings
    bookings: path(ROOTS.app, '/event/bookings'),
    viewBookingDetails: path(ROOTS.app, '/event/bookings/bookingDetails/:id'),

    //Transaction
    transaction: path(ROOTS.app, '/event/transaction'),
    viewTransactionDetails: path(
      ROOTS.app,
      '/event/transaction/transactionDetails/:id'
    ),

    //About
    about: path(ROOTS.app, '/event/about'),

    // //Food
    // food: path(ROOTS.app, '/event/food'),
    // addFood: path(ROOTS.app, '/event/food/addFood'),
    // editFood: path(ROOTS.app, '/event/food/editFood/:id'),
    // viewFood: path(ROOTS.app, '/event/food/viewFood/:id'),

    // Event Management End---------------------
  },
};
