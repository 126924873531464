import clsx from 'clsx';
import { Icon } from '@iconify/react';
import React, { useEffect, useRef, useState } from 'react';
import homeFill from '@iconify-icons/eva/home-fill';
import PopoverMenu from 'src/components/PopoverMenu';
import { Link as RouterLink } from 'react-router-dom';
import personFill from '@iconify-icons/eva/person-fill';
import settings2Fill from '@iconify-icons/eva/settings-2-fill';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Avatar,
  Button,
  Divider,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { MIconButton } from 'src/theme';
import { useHistory, Link } from 'react-router-dom';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '#',
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: '#',
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: '#',
  },
];

const useStyles = makeStyles((theme) => ({
  menuItem: {
    ...theme.typography.body2,
    padding: theme.spacing(1, 2.5),
  },
  btnAvatar: {
    padding: 0,
    width: 44,
    height: 44,
  },
  isSelected: {
    '&:before': {
      zIndex: 1,
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      background: alpha(theme.palette.grey[900], 0.8),
    },
  },
}));

// ----------------------------------------------------------------------

function Account() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [data, setData] = useState();
  const [isOpen, setOpen] = useState(false);
  const [localData, setLocalData] = useState([]);
  const [displayname, setDisplayname] = useState('Admin');
  const [localData1, setLocalData1] = useState([]);
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('admin@gmail.com');

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let history = useHistory();

  // useEffect(() => {
  //   const items = JSON.parse(localStorage.getItem('userDetails'));
  //   if (items) {
  //     setLocalData(items.details);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localData.length !== 0) {
  //     setUserId(localData.userId);
  //   }
  // }, [localData]);

  const logoutFun = () => {
    // fetch('http://192.168.1.10:5000/user/logout?launage=en', {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     userId: userId,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => setData(data.status));

    history.push('/auth/login');
  };

  useEffect(() => {
    if (data === 1) {
      // history.push('/auth/login');
      history.push('/');
    }
  }, [data]);

  // useEffect(() => {
  //   const items = JSON.parse(localStorage.getItem('userDetails'));
  //   if (items) {
  //     setLocalData(items.details);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localData.length !== 0) {
  //     setDisplayname(localData.fullName);
  //     setEmail(localData.email);
  //   }
  // }, [localData]);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        className={clsx(classes.btnAvatar, { [classes.isSelected]: isOpen })}
      >
        <Avatar
          alt="My Avatar"
          src="/static/images/avatars/avatar_default.jpg"
        />
      </MIconButton>

      <PopoverMenu
        width={220}
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ my: 2, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {displayname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            className={classes.menuItem}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={logoutFun}
          >
            Logout
          </Button>
        </Box>
      </PopoverMenu>
    </>
  );
}

export default Account;
