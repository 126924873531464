import { map } from 'lodash';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = { isLoading: false, error: false, team: [], userlist: [] };

const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    getDepartmentSuccess(state, action) {
      state.isLoading = false;
      state.department = action.payload;
    },
    getLocationSuccess(state, action) {
      state.isLoading = false;
      state.location = action.payload;
    },
    getReportToSuccess(state, action) {
      state.isLoading = false;
      state.reportTo = action.payload;
    },
    getRoleSuccess(state, action) {
      state.isLoading = false;
      state.role = action.payload;
    },
    getPhotoUpdateSuccess(state, action) {
      state.isLoading = false;
      state.photoUpdate = action.payload;
    },
    getPhotoDeleteSuccess(state, action) {
      state.isLoading = false;
      state.photoDelete = action.payload;
    },
    getPhotoDeleteSuccess1(state, action) {
      state.isLoading = false;
      state.photoDelete = action.payload;
    },
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userlist = action.payload;
    },
    // START LOADING
    startLoading(state) {
      console.log('start loading');
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      console.log('start error');
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      // console.log('start getUsersSuccess');
      state.isLoading = false;
      state.team = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getTeam(reqData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'http://192.168.1.10:5000/role/list',
        reqData
      );
      // console.log('responded', response.data.responseData);
      dispatch(slice.actions.getTeamSuccess(response.data.responseData));
      // console.log('dispatcheddd', response.data.responseData);
    } catch (error) {
      console.log('errorrrrrr');
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getRole() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('http://192.168.1.10:5000/role/list');
      dispatch(slice.actions.getRoleSuccess(response.data.responseData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getReportTo() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'http://192.168.1.10:5000/user/listAllUser'
      );
      dispatch(slice.actions.getReportToSuccess(response.data.responseData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getLocation() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'http://192.168.1.10:5000/location/list'
      );
      dispatch(slice.actions.getLocationSuccess(response.data.responseData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDepartment() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'http://192.168.1.10:5000/department/list'
      );
      dispatch(slice.actions.getDepartmentSuccess(response.data.responseData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPhotoUpdate(fileField) {
  const formData = new FormData();
  formData.append('image', fileField);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      fetch('http://192.168.1.10:5000/uploadImage?pathType=profile', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          dispatch(slice.actions.getPhotoUpdateSuccess(result.imageUrl));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPhotoDelete(img) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      fetch('http://192.168.1.10:5000/removeImage?imageUrl=' + img, {
        method: 'POST',
        // body: JSON.stringify({ imageUrl: img })
        // body: params
      })
        .then((response) => response.json())
        .then((result) => {
          dispatch(slice.actions.getPhotoDeleteSuccess(result.status));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPhotoDelete1(img, index, param) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // fetch('http://192.168.1.10:5000/removeImage?imageUrl=' + img, {
      axios
        .post('http://192.168.1.10:5000/removeDoc', {
          url: img,
          id: param,
          index: index,
        })
        .then((response) => {
          dispatch(slice.actions.getPhotoDeleteSuccess1(response.status));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserList(userListObj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // fetch('http://192.168.1.10:5000/removeImage?imageUrl=' + img, {
      axios
        .post('http://192.168.1.10:5000/user/getReportUserList', userListObj)
        .then((response) => {
          dispatch(slice.actions.getUserListSuccess(response.data));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
