import React from 'react';
import { PATH_APP } from 'src/routes/paths';
import { MIcon } from 'src/theme';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  dashboard: <MIcon src={path('ic_dashboard')} />,
  team: <MIcon src={path('ic_team')} />,
  customer: <MIcon src={path('ic_customer')} />,
  expense: <MIcon src={path('ic_expense')} />,
  visit: <MIcon src={path('ic_visit')} />,
  raceRegistration: <MIcon src={path('raceReg')} />,
  raceReservation: <MIcon src={path('reservations')} />,
  race: <MIcon src={path('race')} />,
  raceSubtype: <MIcon src={path('raceSub')} />,
  spot: <MIcon src={path('spot')} />,
  shop: <MIcon src={path('shop')} />,
  shopping: <MIcon src={path('shopping')} />,
  vendor: <MIcon src={path('vendor')} />,
  festivalGuide: <MIcon src={path('guide')} />,
  placeEvaluation: <MIcon src={path('evacuationplan')} />,
  user: <MIcon src={path('ic_team')} />,
  outlet: <MIcon src={path('store')} />,
  news: <MIcon src={path('news')} />,
  service: <MIcon src={path('service')} />,
  requestService: <MIcon src={path('reqSer')} />,
  ticket: <MIcon src={path('ticket')} />,
  booking: <MIcon src={path('booking')} />,
  food: <MIcon src={path('food')} />,
  enquiry: <MIcon src={path('conversation')} />,
  transaction: <MIcon src={path('transaction')} />,
  spaceAvailabilityIcon: <MIcon src={path('ready-stock')} />,
  professionalInfluencerIcon: <MIcon src={path('professional')} />,
  safetyMenChallengeIcon: <MIcon src={path('worker')} />,
  familyBusinessIcon: <MIcon src={path('family')} />,
  womeninfluencersIcon: <MIcon src={path('influencer')} />,
  careersIcon: <MIcon src={path('career-promotion')} />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     {
  //       title: 'dashboard',
  //       icon: ICONS.dashboard,
  //       href: PATH_APP.general.root,
  //       items: [
  //         {
  //           title: 'app',
  //           href: PATH_APP.general.root
  //         },
  //         {
  //           title: 'e-commerce',
  //           href: PATH_APP.general.ecommerce
  //         },
  //         {
  //           title: 'analytics',
  //           href: PATH_APP.general.analytics
  //         }
  //       ]
  //     }
  //   ]
  // },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      {
        title: 'User',
        href: PATH_APP.app.user,
        icon: ICONS.team,
      },
      {
        title: 'Race',
        icon: ICONS.race,
        href: PATH_APP.race.root,
        items: [
          {
            title: 'Race Type',
            href: PATH_APP.race.race,
          },
          {
            title: 'Registration',
            href: PATH_APP.race.register,
          },
          // {
          //   title: 'Subtype',
          //   href: PATH_APP.race.subtype,
          // },
          // {
          //   title: 'Race Round',
          //   href: PATH_APP.race.raceRound,
          // },
        ],
      },
      {
        title: 'Family Business',
        href: PATH_APP.app.family,
        icon: ICONS.familyBusinessIcon,
      },
      {
        title: 'Safety Men Challenge',
        href: PATH_APP.app.men,
        icon: ICONS.safetyMenChallengeIcon,
      },
      {
        title: 'Women influencers',
        href: PATH_APP.app.women,
        icon: ICONS.womeninfluencersIcon,
      },
      {
        title: 'Space Availability',
        href: PATH_APP.app.spaceAvailability,
        icon: ICONS.spaceAvailabilityIcon,
      },
      {
        title: 'Professional Influencer',
        href: PATH_APP.app.professionalInfluencer,
        icon: ICONS.professionalInfluencerIcon,
      },
      {
        title: 'Careers',
        href: PATH_APP.app.career,
        icon: ICONS.careersIcon,
      },
      // {
      //   title: 'Reservation',
      //   icon: ICONS.raceReservation,
      //   href: PATH_APP.reservation.root,
      //   items: [
      //     {
      //       title: 'Spot',
      //       href: PATH_APP.reservation.spot,
      //     },
      //     {
      //       title: 'Spot Reservation',
      //       href: PATH_APP.reservation.reservation,
      //     },
      //     {
      //       title: 'Size',
      //       href: PATH_APP.reservation.size,
      //     },
      //   ],
      // },
      {
        title: 'Ticket',
        href: PATH_APP.app.ticket,
        icon: ICONS.ticket,
      },
      // {
      //   title: 'Shop',
      //   icon: ICONS.shop,
      //   href: PATH_APP.shop.root,
      //   items: [
      //     {
      //       title: 'Shop List',
      //       href: PATH_APP.shop.shop,
      //     },
      //     {
      //       title: 'Shop Details',
      //       href: PATH_APP.shop.shopping,
      //     },
      //   ],
      // },

      // {
      //   title: 'Family Business',
      //   icon: ICONS.food,
      //   href: PATH_APP.businessType.root,
      //   items: [
      //     {
      //       title: 'Business Type',
      //       href: PATH_APP.businessType.businessType,
      //     },
      //     {
      //       title: 'Business Registration',
      //       href: PATH_APP.businessType.businessRegistration,
      //     },
      //   ],
      // },
      // {
      //   title: 'Company',
      //   icon: ICONS.vendor,
      //   href: PATH_APP.company.root,
      //   items: [
      //     {
      //       title: 'Vendor',
      //       href: PATH_APP.company.company,
      //     },
      //     {
      //       title: 'Outlet',
      //       href: PATH_APP.company.outlet,
      //     },
      //     {
      //       title: 'Place Evaluation',
      //       href: PATH_APP.company.placeEvaluation,
      //     },
      //   ],
      // },

      // {
      //   title: 'Race Registration',
      //   href: PATH_APP.app.register,
      //   icon: ICONS.raceRegistration,
      // },
      // {
      //   title: 'Spot',
      //   href: PATH_APP.app.spot,
      //   icon: ICONS.spot,
      // },
      // {
      //   title: 'Spot Reservation',
      //   href: PATH_APP.app.reservation,
      //   icon: ICONS.raceReservation,
      // },
      // {
      //   title: 'Race',
      //   href: PATH_APP.app.race,
      //   icon: ICONS.race,
      // },
      // {
      //   title: 'Race Subtype',
      //   href: PATH_APP.app.subtype,
      //   icon: ICONS.raceSubtype,
      // },

      // {
      //   title: 'Shop',
      //   href: PATH_APP.app.shop,
      //   icon: ICONS.shop,
      // },
      // {
      //   title: 'Shopping',
      //   href: PATH_APP.app.shopping,
      //   icon: ICONS.shopping,
      // },
      // {
      //   title: 'Vendor',
      //   href: PATH_APP.app.company,
      //   icon: ICONS.vendor,
      // },
      {
        title: 'Festival Guide',
        href: PATH_APP.app.guide,
        icon: ICONS.festivalGuide,
      },
      // {
      //   title: 'Place Evaluation',
      //   href: PATH_APP.app.placeEvaluation,
      //   icon: ICONS.placeEvaluation,
      // },
      // {
      //   title: 'Outlet',
      //   href: PATH_APP.app.outlet,
      //   icon: ICONS.outlet,
      // },
      {
        title: 'News',
        href: PATH_APP.app.news,
        icon: ICONS.news,
      },
      // {
      //   title: 'Service',
      //   icon: ICONS.service,
      //   href: PATH_APP.service.root,
      //   items: [
      //     {
      //       title: 'Service',
      //       href: PATH_APP.service.service,
      //     },
      //     {
      //       title: 'Request Service',
      //       href: PATH_APP.service.requestService,
      //     },
      //   ],
      // },
      // {
      //   title: 'Service',
      //   href: PATH_APP.app.service,
      //   icon: ICONS.service,
      // },
      // {
      //   title: 'Request Service',
      //   href: PATH_APP.app.requestService,
      //   icon: ICONS.requestService,
      // },
      // {
      //   title: 'Bookings',
      //   href: PATH_APP.app.bookings,
      //   icon: ICONS.booking,
      // },
      {
        title: 'Transaction',
        href: PATH_APP.app.transaction,
        icon: ICONS.transaction,
      },
      {
        title: 'Enquiry',
        href: PATH_APP.app.enquiry,
        icon: ICONS.enquiry,
      },
      // {
      //   title: 'Item One',
      //   href: PATH_APP.app.itemOne,
      //   icon: ICONS.team,
      // },
      // {
      //   title: 'Item Two',
      //   href: PATH_APP.app.itemTwo,
      //   icon: ICONS.team,
      // },
      // {
      //   title: 'Item Three',
      //   href: PATH_APP.app.itemThree,
      //   icon: ICONS.team,
      // },
      // {
      //   title: 'About Us',
      //   href: PATH_APP.app.about,
      //   icon: ICONS.team,
      // },
    ],
  },
  // {
  //   // subheader: 'Report',
  //   items: [
  //     {
  //       title: 'Report',
  //       icon: ICONS.REPORT,
  //       href: PATH_APP.app.root,
  //       items: [
  //         {
  //           title: 'Expenses',
  //           href: PATH_APP.app.expenses
  //         },
  //         {
  //           title: 'Visits',
  //           href: PATH_APP.app.visits
  //         }
  //       ]
  //     }
  //   ]
  // }
];

export default navConfig;
