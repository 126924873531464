import React from 'react';
import TopBar from './TopBar';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    height: '100%'
  }
}));

// ----------------------------------------------------------------------

HomeLayout.propTypes = {
  children: PropTypes.node
};

function HomeLayout({ children }) {
  const classes = useStyles();
  let history = useHistory();

  // history.push('/app/team');
  return (
    <div className={classes.root}>
      {/* <TopBar /> */}
      {/* {history.push('/app/team')} */}
      {history.push('/auth/login')}
      <div className={classes.content}>{children}</div>
    </div>
  );
}

export default HomeLayout;
