import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.root} />,
    },

    // APP
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.form1,
      component: lazy(() => import('src/views/CreateAccount')),
    },
    {
      exact: true,
      path: PATH_APP.app.team,
      component: lazy(() => import('src/views/MyTeam')),
    },
    {
      exact: true,
      path: PATH_APP.app.add_team,
      component: lazy(() => import('src/views/MyTeam/AddTeam')),
    },
    {
      exact: true,
      path: PATH_APP.app.edit_team,
      component: lazy(() => import('src/views/MyTeam/EditIndex')),
    },

    // Event Management Start --------------------

    //Race
    {
      exact: true,
      path: PATH_APP.race.race,
      component: lazy(() => import('src/views/Event/Race'), {
        // timeout: 1000,
      }),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.race.addRaceType,
      component: lazy(() => import('src/views/Event/Race/addRaceTypeIndex'), {
        // timeout: 1000,
      }),
    },
    {
      exact: true,
      path: PATH_APP.race.editRaceType,
      component: lazy(() => import('src/views/Event/Race/editRaceTypeIndex'), {
        // timeout: 1000,
      }),
    },
    {
      exact: true,
      path: PATH_APP.race.viewRaceType,
      component: lazy(() => import('src/views/Event/Race/viewRaceTypeIndex'), {
        // timeout: 1000,
      }),
    },

    //Registration
    {
      exact: true,
      path: PATH_APP.race.register,
      component: lazy(() => import('src/views/Event/Registration'), {
        // timeout: 1000,
      }),
    },
    {
      exact: true,
      path: PATH_APP.race.addRegister,
      component: lazy(() =>
        import('src/views/Event/Registration/addRegisterIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.race.editRegister,
      component: lazy(() =>
        import('src/views/Event/Registration/editRegisterIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.race.viewRegister,
      component: lazy(() =>
        import('src/views/Event/Registration/viewRegisterIndex')
      ),
    },
    //Reservation
    {
      exact: true,
      path: PATH_APP.reservation.reservation,
      component: lazy(() => import('src/views/Event/Reservation'), {
        // timeout: 1000,
      }),
    },
    {
      exact: true,
      path: PATH_APP.reservation.addReservation,
      component: lazy(() =>
        import('src/views/Event/Reservation/addReservationIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.reservation.editReservation,
      component: lazy(() =>
        import('src/views/Event/Reservation/editReservationIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.reservation.viewReservation,
      component: lazy(() =>
        import('src/views/Event/Reservation/viewReservationIndex')
      ),
    },
    //Shopping
    {
      exact: true,
      path: PATH_APP.shop.shopping,
      component: lazy(() => import('src/views/Event/Shopping'), {
        // timeout: 1000,
      }),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.shop.addShopping,
      component: lazy(() =>
        import('src/views/Event/Shopping/addShoppingIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.shop.editShopping,
      component: lazy(() =>
        import('src/views/Event/Shopping/editShoppingIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.shop.viewShopping,
      component: lazy(() =>
        import('src/views/Event/Shopping/viewShoppingIndex')
      ),
    },
    //Spot
    {
      exact: true,
      path: PATH_APP.reservation.spot,
      component: lazy(() => import('src/views/Event/Spot'), {
        // timeout: 1000,
      }),
    },
    {
      exact: true,
      path: PATH_APP.reservation.addSpot,
      component: lazy(() => import('src/views/Event/Spot/addSpotIndex')),
    },
    {
      exact: true,
      path: PATH_APP.reservation.editSpot,
      component: lazy(() => import('src/views/Event/Spot/editSpotIndex')),
    },
    {
      exact: true,
      path: PATH_APP.reservation.viewSpot,
      component: lazy(() => import('src/views/Event/Spot/viewSpotIndex')),
    },

    //Size
    {
      exact: true,
      path: PATH_APP.reservation.size,
      component: lazy(() => import('src/views/Event/Size')),
    },
    {
      exact: true,
      path: PATH_APP.reservation.addSize,
      component: lazy(() => import('src/views/Event/Size/addSizeIndex')),
    },
    {
      exact: true,
      path: PATH_APP.reservation.editSize,
      component: lazy(() => import('src/views/Event/Size/editSizeIndex')),
    },

    //Subtype
    {
      exact: true,
      path: PATH_APP.race.subtype,
      component: lazy(() => import('src/views/Event/raceSubtype')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.race.addSubtype,
      component: lazy(() =>
        import('src/views/Event/raceSubtype/addSubtypeIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.race.editSubtype,
      component: lazy(() =>
        import('src/views/Event/raceSubtype/editSubtypeIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.race.viewSubtype,
      component: lazy(() =>
        import('src/views/Event/raceSubtype/viewSubtypeIndex')
      ),
    },

    //Shop
    {
      exact: true,
      path: PATH_APP.shop.shop,
      component: lazy(() => import('src/views/Event/Shop')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.shop.addShop,
      component: lazy(() => import('src/views/Event/Shop/addShopIndex')),
    },
    {
      exact: true,
      path: PATH_APP.shop.editShop,
      component: lazy(() => import('src/views/Event/Shop/editShopIndex')),
    },
    {
      exact: true,
      path: PATH_APP.shop.viewShop,
      component: lazy(() => import('src/views/Event/Shop/viewShopIndex')),
    },

    //Company
    {
      exact: true,
      path: PATH_APP.company.company,
      // component: lazy(() => import('src/views/Event/Vendor')),
      component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.company.addCompany,
      component: lazy(() => import('src/views/Event/Vendor/addCompanyIndex')),
    },
    {
      exact: true,
      path: PATH_APP.company.editCompany,
      component: lazy(() => import('src/views/Event/Vendor/editCompanyIndex')),
    },
    {
      exact: true,
      path: PATH_APP.company.viewCompany,
      component: lazy(() => import('src/views/Event/Vendor/viewCompanyIndex')),
    },

    //Guide
    {
      exact: true,
      path: PATH_APP.app.guide,
      component: lazy(() => import('src/views/Event/FestivalGuide')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.app.addGuide,
      component: lazy(() =>
        import('src/views/Event/FestivalGuide/addGuideIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.app.editGuide,
      component: lazy(() =>
        import('src/views/Event/FestivalGuide/editGuideIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.app.viewGuide,
      component: lazy(() =>
        import('src/views/Event/FestivalGuide/viewGuideIndex')
      ),
    },
    //Place Evaluation
    {
      exact: true,
      path: PATH_APP.company.placeEvaluation,
      // component: lazy(() => import('src/views/Event/PlaceEvaluation')),
      component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.company.addPlaceEvaluation,
      component: lazy(() =>
        import('src/views/Event/PlaceEvaluation/addPlaceEvaluationIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.company.editPlaceEvaluation,
      component: lazy(() =>
        import('src/views/Event/PlaceEvaluation/editPlaceEvaluationIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.company.viewPlaceEvaluation,
      component: lazy(() =>
        import('src/views/Event/PlaceEvaluation/viewPlaceEvaluationIndex')
      ),
    },

    //Outlet
    {
      exact: true,
      path: PATH_APP.company.outlet,
      // component: lazy(() => import('src/views/Event/Outlet')),
      component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.company.addOutlet,
      component: lazy(() => import('src/views/Event/Outlet/addOutletIndex')),
    },
    {
      exact: true,
      path: PATH_APP.company.editOutlet,
      component: lazy(() => import('src/views/Event/Outlet/editOutletIndex')),
    },
    {
      exact: true,
      path: PATH_APP.company.viewOutlet,
      component: lazy(() => import('src/views/Event/Outlet/viewOutletIndex')),
    },

    //News
    {
      exact: true,
      path: PATH_APP.app.news,
      component: lazy(() => import('src/views/Event/News')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.app.addNews,
      component: lazy(() => import('src/views/Event/News/addNewsIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.editNews,
      component: lazy(() => import('src/views/Event/News/editNewsIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.viewNews,
      component: lazy(() => import('src/views/Event/News/viewNewsIndex')),
    },

    //Service
    {
      exact: true,
      path: PATH_APP.service.service,
      // component: lazy(() => import('src/views/Event/Service')),
      component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.service.addService,
      component: lazy(() => import('src/views/Event/Service/addServiceIndex')),
    },
    {
      exact: true,
      path: PATH_APP.service.editService,
      component: lazy(() => import('src/views/Event/Service/editServiceIndex')),
    },
    {
      exact: true,
      path: PATH_APP.service.viewService,
      component: lazy(() => import('src/views/Event/Service/viewServiceIndex')),
    },
    //Request Service
    {
      exact: true,
      path: PATH_APP.service.requestService,
      // component: lazy(() => import('src/views/Event/RequestService')),
      component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.service.addRequestService,
      component: lazy(() =>
        import('src/views/Event/RequestService/addRequestIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.service.editRequestService,
      component: lazy(() =>
        import('src/views/Event/RequestService/editRequestIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.service.viewRequestService,
      component: lazy(() =>
        import('src/views/Event/RequestService/viewRequestIndex')
      ),
    },
    //User
    {
      exact: true,
      path: PATH_APP.app.user,
      component: lazy(() => import('src/views/Event/User')),
    },
    {
      exact: true,
      path: PATH_APP.app.addUser,
      component: lazy(() => import('src/views/Event/User/addUserIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.editUser,
      component: lazy(() => import('src/views/Event/User/editUserIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.viewUser,
      component: lazy(() => import('src/views/Event/User/viewUserIndex')),
    },

    //Ticket
    {
      exact: true,
      path: PATH_APP.app.ticket,
      component: lazy(() => import('src/views/Event/Ticket')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.app.addTicket,
      component: lazy(() => import('src/views/Event/Ticket/addTicketIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.editTicket,
      component: lazy(() => import('src/views/Event/Ticket/editTicketIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.viewTicket,
      component: lazy(() => import('src/views/Event/Ticket/viewTicketIndex')),
    },

    //Bookings
    {
      exact: true,
      path: PATH_APP.app.bookings,
      component: lazy(() => import('src/views/Event/Booking')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.app.viewBookingDetails,
      component: lazy(() => import('src/views/Event/Booking/bookingView')),
    },

    //Transaction
    {
      exact: true,
      path: PATH_APP.app.transaction,
      component: lazy(() => import('src/views/Event/TransactionDetails')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.app.viewTransactionDetails,
      component: lazy(() =>
        import('src/views/Event/TransactionDetails/transactionView')
      ),
    },

    //RaceRound
    {
      exact: true,
      path: PATH_APP.race.raceRound,
      component: lazy(() => import('src/views/Event/RaceRound')),
    },
    {
      exact: true,
      path: PATH_APP.race.addRaceRound,
      component: lazy(() =>
        import('src/views/Event/RaceRound/addRaceRoundIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.race.editRaceRound,
      component: lazy(() =>
        import('src/views/Event/RaceRound/editRaceRoundIndex')
      ),
    },

    //About
    {
      exact: true,
      path: PATH_APP.app.about,
      component: lazy(() => import('src/views/Event/About')),
    },

    // Enquiry
    {
      exact: true,
      path: PATH_APP.app.enquiry,
      component: lazy(() => import('src/views/Event/Enquiry')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.app.viewEnquiry,
      component: lazy(() => import('src/views/Event/Enquiry/viewEnquiryIndex')),
    },

    // //FamilyBusiness
    // {
    //   exact: true,
    //   path: PATH_APP.business.food,
    //   component: lazy(() => import('src/views/Event/Food')),
    //   // component: lazy(() => import('src/views/Event/ComingSoonView')),
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.business.addFood,
    //   component: lazy(() => import('src/views/Event/Food/addFoodIndex')),
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.business.editFood,
    //   component: lazy(() => import('src/views/Event/Food/editFoodIndex')),
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.business.viewFood,
    //   component: lazy(() => import('src/views/Event/Food/viewFoodIndex')),
    // },

    //BusinessType
    {
      exact: true,
      path: PATH_APP.businessType.businessType,
      component: lazy(() => import('src/views/Event/BusinessType')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.businessType.addBusinessType,
      component: lazy(() =>
        import('src/views/Event/BusinessType/addBusinessTypeIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.businessType.editBusinessType,
      component: lazy(() =>
        import('src/views/Event/BusinessType/editBusinessTypeIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.businessType.viewBusinessType,
      component: lazy(() =>
        import('src/views/Event/BusinessType/viewBusinessTypeIndex')
      ),
    },

    //Family Business
    {
      exact: true,
      path: PATH_APP.app.family,
      component: lazy(() => import('src/views/Event/FamilyBusiness')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.app.addFamily,
      component: lazy(() =>
        import('src/views/Event/FamilyBusiness/addFamilyBusinessIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.app.editFamily,
      component: lazy(() =>
        import('src/views/Event/FamilyBusiness/editFamilyBusinessIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.app.viewFamily,
      component: lazy(() =>
        import('src/views/Event/FamilyBusiness/viewFamilyBusinessIndex')
      ),
    },

    //women influencers
    {
      exact: true,
      path: PATH_APP.app.women,
      component: lazy(() => import('src/views/Event/WomenInfluencers')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.app.addWomen,
      component: lazy(() =>
        import('src/views/Event/WomenInfluencers/addWomenInfluencersIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.app.editWomen,
      component: lazy(() =>
        import('src/views/Event/WomenInfluencers/editWomenInfluencersIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.app.viewWomen,
      component: lazy(() =>
        import('src/views/Event/WomenInfluencers/viewWomenInfluencersIndex')
      ),
    },

    //safety men challenge
    {
      exact: true,
      path: PATH_APP.app.men,
      component: lazy(() => import('src/views/Event/SafetyMenChallenge')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.app.addMen,
      component: lazy(() =>
        import('src/views/Event/SafetyMenChallenge/addSafetyMenChallengeIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.app.editMen,
      component: lazy(() =>
        import('src/views/Event/SafetyMenChallenge/editSafetyMenChallengeIndex')
      ),
    },
    {
      exact: true,
      path: PATH_APP.app.viewMen,
      component: lazy(() =>
        import('src/views/Event/SafetyMenChallenge/viewSafetyMenChallengeIndex')
      ),
    },

    //Career
    {
      exact: true,
      path: PATH_APP.app.career,
      component: lazy(() => import('src/views/Event/Career')),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.app.addCareer,
      component: lazy(() => import('src/views/Event/Career/addCareerIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.editCareer,
      component: lazy(() => import('src/views/Event/Career/editCareerIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.viewCareer,
      component: lazy(() => import('src/views/Event/Career/viewCareerIndex')),
    },


    // Space Availability
    {
      exact: true,
      path: PATH_APP.app.spaceAvailability,
      component: lazy(() => import('src/views/Event/SpaceAvailability')),
    },
    {
      exact: true,
      path: PATH_APP.app.addSpaceAvailability,
      component: lazy(() => import('src/views/Event/SpaceAvailability/addSpaceAvailabilityIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.editSpaceAvailability,
      component: lazy(() => import('src/views/Event/SpaceAvailability/editSpaceAvailabilityIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.viewSpaceAvailability,
      component: lazy(() => import('src/views/Event/SpaceAvailability/viewSpaceAvailabilityIndex')),
    },

    // ProfessionalInfluencer
    {
      exact: true,
      path: PATH_APP.app.professionalInfluencer,
      component: lazy(() => import('src/views/Event/ProfessionalInfluencer')),
    },
    {
      exact: true,
      path: PATH_APP.app.addProfessionalInfluencer,
      component: lazy(() => import('src/views/Event/ProfessionalInfluencer/addProfessionalInfluencerIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.editProfessionalInfluencer,
      component: lazy(() => import('src/views/Event/ProfessionalInfluencer/editProfessionalInfluencerIndex')),
    },
    {
      exact: true,
      path: PATH_APP.app.viewProfessionalInfluencer,
      component: lazy(() => import('src/views/Event/ProfessionalInfluencer/viewProfessionalInfluencerIndex')),
    },


    //Registration
    {
      exact: true,
      path: PATH_APP.businessType.businessRegistration,
      component: lazy(() =>
        import('src/views/Event/BusinessRegistration/businessRegistrationList')
      ),
      // component: lazy(() => import('src/views/Event/ComingSoonView')),
    },
    {
      exact: true,
      path: PATH_APP.businessType.addBusinessRegistration,
      component: lazy(() =>
        import(
          'src/views/Event/BusinessRegistration/addBusinessRegistrationIndex'
        )
      ),
    },
    {
      exact: true,
      path: PATH_APP.businessType.editBusinessRegistration,
      component: lazy(() =>
        import(
          'src/views/Event/BusinessRegistration/editBusinessRegistrationIndex'
        )
      ),
    },
    {
      exact: true,
      path: PATH_APP.businessType.viewBusinessRegistration,
      component: lazy(() =>
        import(
          'src/views/Event/BusinessRegistration/viewBusinessRegistrationIndex'
        )
      ),
    },

    // //Registration
    // {
    //   exact: true,
    //   path: PATH_APP.race.register,
    //   component: lazy(() => import('src/views/Event/Registration')),
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.race.addRegister,
    //   component: lazy(() =>
    //     import('src/views/Event/Registration/addRegisterIndex')
    //   ),
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.race.editRegister,
    //   component: lazy(() =>
    //     import('src/views/Event/Registration/editRegisterIndex')
    //   ),
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.race.viewRegister,
    //   component: lazy(() =>
    //     import('src/views/Event/Registration/viewRegisterIndex')
    //   ),
    // },

    // Event Management End ------------------------
    {
      exact: true,
      path: PATH_APP.app.login,
      component: lazy(() => import('src/views/auth/LoginView')),
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default AppRoutes;
