import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './slices/settings';
import TeamReducer from './slices/team';
import VisitReducer from './slices/visit';
import ExpenseReducer from './slices/expense';
import userReducer from './slices/user';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  team: TeamReducer,
  visit: VisitReducer,
  expense: ExpenseReducer,
  user: userReducer
});

export { rootPersistConfig, rootReducer };
