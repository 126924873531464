import { map } from 'lodash';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = { isLoading: false, error: false, expense: [] };

const slice = createSlice({
  name: 'expense',
  initialState,
  reducers: {
    getPhotoUpdateSuccess(state, action) {
      state.isLoading = false;
      state.photoUpdate = action.payload;
    },

    // START LOADING
    startLoading(state) {
      console.log('start loading');
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      console.log('start error');
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      // console.log('start getUsersSuccess');
      state.isLoading = false;
      state.expense = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getPhotoUpdate(fileField) {
  const formData = new FormData();
  formData.append('image', fileField);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      fetch('http://192.168.1.10:5000/uploadImage?pathType=expense', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          dispatch(slice.actions.getPhotoUpdateSuccess(result.imageUrl));
          // console.log(result.imageUrl)
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
