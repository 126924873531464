import { map } from 'lodash';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = { isLoading: false, error: false, visit: [] };

const slice = createSlice({
  name: 'visit',
  initialState,
  reducers: {
    getAssignedToSuccess(state, action) {
      state.isLoading = false;
      state.assignedTo = action.payload;
    },
    getPurposeSuccess(state, action) {
      state.isLoading = false;
      state.purpose = action.payload;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.visit = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getPurpose() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'http://192.168.1.10:5000/visitPurpose/list?language=en'
      );
      dispatch(slice.actions.getPurposeSuccess(response.data.responseData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getAssignedTo(assignedToUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios
        // .post('http://192.168.1.10:5000/user/getReportUserList', {
        .post(
          'http://192.168.1.10:5000/user/userListByAdminUserId?language=en',
          {
            userId: assignedToUserId,
          }
        )
        .then((response) => {
          dispatch(
            slice.actions.getAssignedToSuccess(response.data.responseData)
          );
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
